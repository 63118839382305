// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type {
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 30px;
  display: flex;
  justify-items: center;
}
.type:hover {
  border: 1px solid #00833d;
}

.date-range {
  background-color: rgba(181, 186, 181, 0.25);
  font-size: 14;
  border: none;
  flex-grow: 1;
  border-radius: 4px;
  padding-inline: 4px;
}

.date-range:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/retraits/styles/retraits.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2CAA2C;EAC3C,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".type {\n  padding: 10px 30px;\n  cursor: pointer;\n  border-radius: 4px;\n  margin-right: 30px;\n  display: flex;\n  justify-items: center;\n}\n.type:hover {\n  border: 1px solid #00833d;\n}\n\n.date-range {\n  background-color: rgba(181, 186, 181, 0.25);\n  font-size: 14;\n  border: none;\n  flex-grow: 1;\n  border-radius: 4px;\n  padding-inline: 4px;\n}\n\n.date-range:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
