import React, { useState } from 'react';
import { Box, Button } from "@mui/material";
import TabUtilisateurs from "./TabUtilisateurs";
import AjouterUtilisateur from "./AjouterUtilisateur";
import TabRole from "./TabRole";

const GestionDesUtilisateur = () => {
    const [activeTab, setActiveTab] = useState('utilisateurs');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Box
            sx={{
                borderRadius: 2,
                flex: '0 0 75%',
                '@media (max-width: 600px)': { flex: '0 0 100%' }
            }}
        >
            <Box sx={{ width: "100%", display: 'flex', marginBottom: 2 }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: activeTab === 'utilisateurs' ? '#212121' : '#DDE4DD',
                        color: activeTab === 'utilisateurs' ? '#ffffff' : '#000',
                        boxShadow: 'none',
                        width: '50%',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: activeTab === 'utilisateurs' ? '#212121' : '#DDE4DD',
                            color: activeTab === 'utilisateurs' ? '#ffffff' : '#000',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={() => handleTabChange('utilisateurs')}
                >
                    Gestion des utilisateurs
                </Button>

                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: activeTab === 'role' ? '#212121' : '#DDE4DD',
                        color: activeTab === 'role' ? '#ffffff' : '#000',
                        width: '50%',
                        boxShadow: 'none',
                        mx: 1.5,
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: activeTab === 'role' ? '#212121' : '#DDE4DD',
                            color: activeTab === 'role' ? '#ffffff' : '#000',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={() => handleTabChange('role')}
                >
                    Rôle
                </Button>
            </Box>

            {activeTab === 'utilisateurs' ? <TabUtilisateurs /> : <TabRole />}
        </Box>
    );
};

export default GestionDesUtilisateur;
