import React from 'react'
import { Typography, Box,Toolbar } from "@mui/material";


const Boss = () => {
  return (
    <div>
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
    >
      <Toolbar />
      <Typography paragraph>
        Bara.
      </Typography>
    </Box>
  </div>
  )
}

export default Boss