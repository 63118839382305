import React, { useState } from "react";

import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  Link,
  FormControlLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { LoginButton } from "../../../assets/styles/Widgets";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
// import ApiUrl from '../../utils/api/ApiUrl';
import { useDispatch } from "react-redux";
import {
  updateLocalUserInfo,
  reducerUserData,
} from "../../../reducers/sessionReducer";
import { setCookie } from "cookies-next";
import { useSelector } from "react-redux";
import AuthCtrl from "../../controllers/auth_controller";
import LoginDto from "../../dto/loginDto";
import CustomToast from "../../../helpers/customToast";

const LoginForm = () => {
  const localUserData = useSelector(reducerUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const login = async () => {
    if (email.trim().length === 0) {
      return;
    }

    const postData = {
      telephone: email,
      pin: password,
      version: 0,
      platform: "admin",
    };

    setOpenBackdrop(true);

    await axios
      .post("https://apirecette.jevebara.com/api/login", postData)
      .then((res) => {
        localStorage.setItem("userData", JSON.stringify(res.data));
        dispatch(updateLocalUserInfo(res.data));
        setCookie("tokenBARA", localUserData.token, {
          maxAge: localUserData.expiresIn,
        });
        sleep(2000).then(() => {
          setOpenBackdrop(false);
          navigate("/");
        });
      })
      .catch((err) => {
        setOpenBackdrop(false);
        console.log(err);
      });
  };

  const login2 = async () => {
    if (email.trim().length === 0) {
      CustomToast.showWarnin(
        "Veuillez saisir un e-mail ou un numéro de téléphone valide"
      );
    } else if (password.trim().length < 4) {
      CustomToast.showWarnin("Veuillez saisir un mot de passe valide");
    } else {
      setOpenBackdrop(true);
      AuthCtrl.login(
        new LoginDto(email, password),
        (res) => {
          localStorage.setItem("userData", JSON.stringify(res.content));
          dispatch(updateLocalUserInfo(res.content));
          setCookie("tokenBARA", localUserData.token, {
            maxAge: localUserData.expiresIn,
          });
          setCookie("nom", res.content.nom);
          setCookie("role", res.content.role);
          CustomToast.showSuccess("Connexion Réussi");
          setOpenBackdrop(false);
          navigate("/home");
        },
        () => {
          setOpenBackdrop(false);
          console.log("err");
        }
      );
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        noValidate
        // onSubmit={handleSubmit}
        sx={{
          mt: 4,
          m: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              fontWeight: "700",
              fontSize: "24px",
              m: 0,
              py: 1,
            }}
          >
            Bonjour !
          </Box>
        </Typography>

        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              fontWeight: "500",
              fontSize: "14px",
              mt: 3,
            }}
          >
            Votre adresse mail
          </Box>
        </Typography>

        <FormControl sx={{ width: "100%", mt: 1, mb: 2 }} variant="outlined">
          <OutlinedInput
            fullWidth
            sx={{
              borderRadius: 1,
              height: "52px",
              "& input::placeholder": { fontSize: "14px" },
              "&:hover": {
                bordercolor: "primary",
              },
            }}
            id="email"
            autoComplete="email"
            placeholder="Votre adresse mail"
            onChange={(event) => handleInputChange(event, setEmail)}
          />
        </FormControl>

        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              fontWeight: "500",
              fontSize: "14px",
              mt: 0,
            }}
          >
            Mot de passe
          </Box>
        </Typography>

        <FormControl sx={{ width: "100%", mt: 1, mb: 2 }} variant="outlined">
          <OutlinedInput
            sx={{
              height: "52px",
              borderRadius: 1,

              "& input::placeholder": { fontSize: "14px" },
            }}
            id="password"
            type={showPassword ? "text" : "password"}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {/* {showPassword ? <Lock /> : <Lock />} */}
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Votre mot de passe"
            onChange={(event) => handleInputChange(event, setPassword)}
          />
        </FormControl>
        {/* <FormControlLabel
          // sx={{ fontSize: "12px", }}
          value="end"
          control={
            <Checkbox
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 28,
                  borderRadius: 3,
                },
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              Se souvenir de moi
            </Typography>
          }
          labelPlacement="end"
        /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LoginButton onClick={() => login2()}>Se connecter</LoginButton>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <span>
            <strong>
              <Link
                // href="verify"
                onClick={() => navigate("/reset-password/")}
                variant="body2"
                fontWeight="bold"
                fontSize="16px"
                sx={{ cursor: "pointer" }}
              >
                {"Mot de passe oublié ?"}
              </Link>
            </strong>
          </span>
        </Box>
        {/* <Box sx={{ textAlign: "left", mt: 4 }}>
          Vous n'avez pas encore de compte?{" "}
          <span>
            <strong>
              <Link
                // href="verify"
                onClick={() => navigate("/register/")}
                variant="body2"
                fontWeight="bold"
                fontSize="16px"
                sx={{ cursor: "pointer" }}
              >
                {"Inscrivez-vous"}
              </Link>
            </strong>
          </span>
        </Box> */}
      </Box>
    </>
  );
};

export default LoginForm;
