import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dropzone from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import axios from 'axios';
import CustomToast from '../../../../../helpers/customToast';
import Api from '../../../../../services/http/api';

const CategoryEditModal = ({ open, onClose, selectedSubcategory, handleSubmitCategoryName }) => {

  const [categoryLabel, setCategoryLabel] = useState('');
  const [categoryFileReference, setCategoryFileReference] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [error, setError] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    if (selectedSubcategory) {
      setCategoryLabel(selectedSubcategory.libelle || '');
      setCategoryFileReference(selectedSubcategory.referenceFichier || null);
      setIsAvailable(selectedSubcategory.disponible || false);
    }
  }, [selectedSubcategory]);

  const handleSubmit = () => {
    if (categoryLabel && categoryFileReference) {
      const editedCategory = {
        ...selectedSubcategory,
        id: selectedSubcategory.id,
        libelle: categoryLabel,
        referenceFichier: categoryFileReference,
        libelleSuperCategorie: selectedSubcategory.libelleSuperCategorie
      };
      handleSubmitCategoryName(editedCategory);
      setCategoryLabel('');
      setCategoryFileReference(null);
      setIsAvailable(false);
      onClose();
    } else {
      CustomToast.showError("Libellé et fichier requis.");
    }
  };

  const handleDrop = async (acceptedFiles) => {
    const validExtensions = ['.png', '.jpg', '.jpeg'];
    const file = acceptedFiles[0];
    const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

    if (!validExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      CustomToast.showWarnin("Extensions acceptées (.PNG, .JPG ou .JPEG).");
      return;
    }
    const token = sessionStorage.getItem("token");
    try {
      const toastId = CustomToast.showLoading("fichier en cours Importation.");
      setCategoryFileReference(null)
      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.post(
        `${Api.baseUrl}/api/v3/admin-all/file`,
        formData,
        config
      );
      console.log('Réponse du serveur :', response.data.content.path);
      setCategoryFileReference(response.data.content.path)
      CustomToast.hideLoading(toastId);
      CustomToast.showSuccess("Image importeé avec succès");
      console.log("reference : ", response.data.content.path)
    } catch (error) {
      CustomToast.showError("Impossible d'importer cette image !");
      console.error('Une erreur s\'est produite lors du téléchargement du fichier :', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ width: 500, p: 3, mx: 'auto', mt: '10%', backgroundColor: 'background.paper', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6" component="h2" sx={{ flexGrow: 1, fontWeight: 'bold', textAlign: 'center' }}>
            Modifier cette catégorie
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          label="Nom de la catégorie"
          variant="outlined"
          value={categoryLabel}
          onChange={(e) => setCategoryLabel(e.target.value)}
          sx={{ mb: 2 }}
        />
        {error && <Alert severity="error" sx={{ m: .5 }}>{error}</Alert>}
        <Dropzone onDrop={handleDrop} accept=".png, .jpg, .jpeg">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}
                style={{
                  border: '1px solid #CECECE',
                  height: 150,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '5px',
                  backgroundColor: '#F8F8F8',
                  cursor: 'pointer'
                }}>
                <input {...getInputProps()} />
                <CloudUploadOutlinedIcon sx={{ fontSize: '80px', color: '#C9C9C9' }} />
                <Typography>
                  {categoryFileReference ? 'Image sélectionnée avec succès' : 'Sélectionnez ou déposez une image'}
                </Typography>
              </div>
            </section>
          )}
        </Dropzone>
        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
          Modifier
        </Button>
      </Box>
    </Modal>
  );
};

export default CategoryEditModal;
