// DeleteConfirmationModal.jsx
import React from 'react';
import { Modal, Box, Button, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

const DeleteConfirmationModal = ({ open, onClose, selectedSubcategory, confirmDeleteCategory }) => {
    const modalDisplay = open ? 'block' : 'none';

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{width: 600,  display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, minWidth: 100, borderRadius: 3, display: modalDisplay }}>
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 1 }}>Supprimer</DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description" sx={{ color: '#000', textAlign: 'center' }}>
                        Vous êtes sur le point de supprimer cette sous-catégorie. Cela entraînera la perte de toutes ses données d’assignations ainsi que les sous-catégories associées.
                    </DialogContentText>
                </DialogContent>
                <div style={{ color: '#EF2E2E', textAlign: 'center', fontWeight: 'bold' }}>Supprimer cette sous-catégorie ?</div>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button onClick={onClose} sx={{ marginRight: "55%", textTransform: 'none', color: '#000' }}>
                        Non
                    </Button>
                    <Button onClick={confirmDeleteCategory} color="primary" autoFocus sx={{ textTransform: 'none' }}>
                        Oui, supprimer
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteConfirmationModal;