const ApiUrl = {
  baseUrl: "https://apirecette.jevebara.com",

  register: "api/utilisateur/register-admin",
  login: "/api/login",
  logout: "logout",
  verify: "auth/verify/",
  changePassword: "auth/change_password/",
  resetPassword: "auth/reset_password/",

};

export default ApiUrl;

