import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatDate } from "../../../assets/styles/Widgets";

const DetailsTransaction = () => {
  const items = useLocation().state.data;
  const [transactionData, setTransactionData] = useState(items.details);

  const SetDetails = () => {
    setTransactionData(items.details);
  };

  useEffect(() => {
    SetDetails();
  });

  return (
    <div
      style={{
        marginTop: 100,
        marginInline: 20,
        padding: 30,
      }}
    >
      <p
        style={{
          color: "#001",
          fontSize: 22,
          fontWeight: 600,
          marginBottom: 60,
        }}
      >
        Détail de la transaction
      </p>
      <div style={styles.container}>
        <div style={styles.column}>
          <p style={styles.label}>Date</p>
          <p style={styles.value}>
            {formatDate(transactionData.dateCreation)}
          </p>
          <p style={styles.label}>Nom et prénom du destinataire</p>
          <p style={styles.value}>
            {transactionData.destinataire.nom}{" "}
            {transactionData.destinataire.prenom}
          </p>
        </div>
        <div style={styles.column}>
          <p style={styles.label}>Valeur</p>
          <p style={styles.value}>{transactionData.valeur}XOF</p>
          <p style={styles.label}>Numéro de la transaction</p>
          <p style={styles.value}>{transactionData.numeroTransaction}</p>
        </div>
        <div style={styles.column}>
          <p style={styles.label}>Type</p>
          <p style={styles.value}>{transactionData.typeOperation}</p>
          <p style={styles.label}>Description</p>
          <p style={styles.value}>{transactionData.description}</p>
        </div>
        <div style={styles.column}>
          <p style={styles.label}>Emetteur</p>
          <p style={styles.value}>{transactionData.emetteur.nom}</p>
          <p style={styles.label}>Statut</p>
          <p style={styles.value}>{transactionData.statutTransaction}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  column: {
    flex: "1 1 200px",
    padding: "0 20px",
    boxSizing: "border-box",
    minWidth: 200, 
    marginBottom: 30, 
  },
  label: {
    color: "#9BA69C",
    fontSize: 14,
    marginBottom: 6,
  },
  value: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 60,
  },
};

export default DetailsTransaction;
