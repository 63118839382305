import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import CategoryDeleteConfirmationModal from './components/CategoryDeleteConfirmationModal';
import CategoryEditModal from './components/CategoryEditModal';
import CategoryItem from './components/CategoryItem';
import CategoryAddModal from './components/CategoryAddModal';
import Swal from 'sweetalert2';
import axios from 'axios';

import Api from '../../../../services/http/api';
import CustomToast from '../../../../helpers/customToast';
import categorieAddDto from '../../dto/categorieAddDto';
import categorieDeleteDto from '../../dto/categorieDeleteDto';
import categorieEditDto from '../../dto/categorieEditDto';

const CategoryList = ({
  categories,
  filter,
  handleFilterChange,
  page,
  categoriesPerPage,
  handleCategoryClick,
  handlePreviousPage,
  handleNextPage,
  renderPageButtons,
  totalPages,
  handlePageChange,
  fetchCategories
}) => {

  console.log(categories)

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const openConfirmationModal = (category) => {
    setCategoryToDelete(category);
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setCategoryToDelete(null);
    setShowConfirmationModal(false);
  };

  const confirmDeleteCategory = async () => {
    closeConfirmationModal()
    const categorie = {
      disponible: false,
      libelle: categoryToDelete.libelle,
      referenceFichier: categoryToDelete.referenceFichier,
    }
    const toastId = CustomToast.showLoading("Suppression en cours.");
    const dto = new categorieDeleteDto(categoryToDelete.id, categorie);
    console.log(dto);

    Api.call(
      dto,
      async (res) => {
        console.log(res);
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchCategories();
      },
      (err) => { }
    );
  };

  const openEditModal = (category) => {
    setCategoryToEdit(category);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setCategoryToEdit(null);
    setShowEditModal(false);
  };

  const handleSubmitCategoryName = async (editedCategory) => {
    const categorie = {
      disponible: editedCategory.disponible,
      libelle: editedCategory.libelle,
      referenceFichier: editedCategory.referenceFichier,
    }

    const toastId = CustomToast.showLoading("Modification en cours.");
    const dto = new categorieEditDto(editedCategory.id, categorie);
    console.log(dto);

    Api.call(
      dto,
      async (res) => {
        console.log(res);
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchCategories();
      },
      (err) => { }
    );
  };



  const handleAddCategory = async (newCategory) => {
    setShowAddModal(false)
    const toastId = CustomToast.showLoading("Enregistrement en cours.");
    const dto = new categorieAddDto(newCategory);
    console.log(dto);

    Api.call(
      dto,
      async (res) => {
        console.log(res);
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchCategories();
      },
      (err) => { }
    );
  };


  return (
    <Box sx={{ width: "80%", backgroundColor: 'background.paper', p: 2, borderRadius: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, textAlign: 'center' }}>Liste des catégories</Typography>
      <Box sx={{ display: 'flex' }}>
        <TextField
          label="Catégories"
          variant="outlined"
          size="small"
          fullWidth
          value={filter}
          sx={{ mb: 1, width: "75%" }}
          onChange={handleFilterChange}
          placeholder='Recherchez une catégorie'
          InputProps={{
            startAdornment: <SearchIcon sx={{ fontSize: '23px', mr: 1 }} />,
          }}
        />
        <Button variant="contained" sx={{ mb: 1, width: "30%", mx: 1 }} onClick={() => setShowAddModal(true)}>
          Ajouter <AddCircleOutlineIcon sx={{ mx: .5 }} />
        </Button>
      </Box>

      {/* Affichage des catégories */}
      {categories
        .filter(category => {
          const libelle = category.libelle ? category.libelle.toLowerCase() : '';
          const filterText = filter.toLowerCase();
          const isActiveAndAvailable = category.actif === true && category.disponible === true;
          const matchesFilter = libelle.includes(filterText);
          console.log('Category:', category, 'Matches Filter:', matchesFilter);
          return isActiveAndAvailable && matchesFilter;
        })
        .slice((page - 1) * categoriesPerPage, page * categoriesPerPage)
        .map((category, index) => (
          <CategoryItem
            key={index}
            category={category}
            handleCategoryClick={handleCategoryClick}
            openConfirmationModal={openConfirmationModal}
            openEditModal={openEditModal}
          />
        ))
      }




      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
        <Button onClick={handlePreviousPage} disabled={page === 1}>Précédent</Button>
        {renderPageButtons(totalPages, page, handlePageChange)}
        <Button onClick={handleNextPage} disabled={page === totalPages}>Suivant</Button>
      </Box>


      {/* Modal de Confirmation de Suppression */}
      <CategoryDeleteConfirmationModal
        open={showConfirmationModal}
        onClose={closeConfirmationModal}
        categoryToDelete={categoryToDelete}
        updateCategories={categories} // Passer la fonction updateCategories comme prop
        confirmDeleteCategory={confirmDeleteCategory}
      />

      {/* Modal de Modification de Catégorie */}
      <CategoryEditModal
        open={showEditModal}
        onClose={closeEditModal}
        categoryToEdit={categoryToEdit}
        handleSubmitCategoryName={handleSubmitCategoryName}
      />

      {/* Modal d'ajout de catégorie */}
      <CategoryAddModal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        handleAddCategory={handleAddCategory}
      />
    </Box>
  );
};

export default CategoryList;
