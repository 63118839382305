import React from "react";
import { Box, Avatar, Typography, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import { AppButton, TextBox1 } from "../../assets/styles/Widgets";
import Image from "../../assets/img/profile.png";
import { Toolbar } from "yet-another-react-lightbox";

const ProfileGrouilleur = () => {
  const userData = {
    nom: "Ubeujum",
    prenom: "Blyne",
    telephone: "+23455779994",
    adresse: "Abijan, Cocody",
    presentation:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a",
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "background.default",
          height: "100vh",
          p: 3,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            m: 2,
            textAlign: "center",
          }}
        >
          Profile du Grouilleur
        </Typography>

        <Avatar
          sx={{
            height: "310px",
            width: "310px",
            bgcolor: "white",
            border: "2px solid #00833D",
            mb: 2,
          }}
        >
          <Avatar
            sx={{
              height: "300px",
              width: "300px",
              bgcolor: "white",
            }}
            alt="Photo de profil"
            src={Image}
          />
        </Avatar>

        <Box
          sx={{
            maxWidth: { xs: "100%", md: "500px" },
            textAlign: "center",
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600", mb: 1 }}>
            {userData.nom} {userData.prenom}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "400", mb: 4 }}>
            Crée le 12/12/2024
          </Typography>
          <Divider sx={{ my: 1 }} />

          <Box sx={{ textAlign: "center", my: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "700", mb: 2 }}>
              Informations basiques
            </Typography>
            <Box>
              <TextBox1>Localisation</TextBox1>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {userData.adresse}
              </Typography>

              <TextBox1>Présentation</TextBox1>
              <Box
                sx={{
                  maxHeight: "130px",
                  width: "100%",
                  // overflowY: "auto",
                  // scrollbarWidth: "thin",
                  // "&::-webkit-scrollbar": {
                  //   width: "2px",
                  // },
                  // "&::-webkit-scrollbar-track": {
                  //   backgroundColor: "#f1f1f1",
                  // },
                  // "&::-webkit-scrollbar-thumb": {
                  //   backgroundColor: "#888",
                  //   borderRadius: "5px",
                  // },
                }}
              >
                <Typography variant="body2">{userData.presentation}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display:"flex", gap:1 }} >
            <AppButton
              startIcon={
                <Icon
                  icon="fluent-emoji-high-contrast:telephone-receiver"
                  style={{ color: "white" }}
                />
              }
              sx={{ background: "#00833D", width: "60%", my: 2 }}
            >
              Contacter
            </AppButton>
            <AppButton
              startIcon={
                <Icon icon="jam:picture-f"  style={{color: "white"}} />
              }
              sx={{ background: "#00833D", width: "60%", my: 2 }}
            >
              Realisation
            </AppButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileGrouilleur;
