import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button
} from "@mui/material";
import axios from 'axios';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Api from '../../../services/http/api';
import CustomToast from '../../../helpers/customToast';
import updatePasswordDto from '../dto/updatePasswordDto';

const GestionDeMotDePasse = () => {

    const [loading, setLoading] = useState(false);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [showPasswords, setShowPasswords] = useState({ oldPassword: false, newPassword: false, confirmNewPassword: false });

    const togglePasswordVisibility = (fieldName) => {
        setShowPasswords((prevState) => ({
            ...prevState,
            [fieldName]: !prevState[fieldName]
        }));
    };

    const resetForm = () => { formik.resetForm(); };
    const setLoader = (status) => { setLoading(status); setFieldsDisabled(status); };

    const validationSchema = yup.object().shape({
        oldPassword: yup.string().required('Le champ actuel est requis')
            .min(4, 'Renseignez au moins 04 caractères')
            .max(20, 'Renseignez au plus 20 caractères'),
        newPassword: yup.string().required('Le champ actuel est requis')
            .min(4, 'Renseignez au moins 04 caractères')
            .max(20, 'Renseignez au plus 20 caractères'),
        confirmNewPassword: yup.string().oneOf(
            [yup.ref('newPassword'), null],
            'Les mots de passe doivent correspondre')
            .required('Le champ actuel est requis')
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoader(true);
            const toastId = CustomToast.showLoading("Modification en cours.");
            const dto = new updatePasswordDto(values.confirmNewPassword, values.newPassword, values.oldPassword);
            console.log(dto);
            Api.call(
                dto,
                async (res) => {
                    resetForm();
                    setLoader(false);
                    CustomToast.hideLoading(toastId);
                    CustomToast.showSuccess(res.message);
                },
                (err) => {
                    setLoader(false);
                    console.error("Erreur lors de la modification :", err);
                    CustomToast.hideLoading(toastId);
                }
            );
        },
    });

    return (
        <>
            <ToastContainer />
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: '0 0 75%', '@media (max-width: 600px)': { flex: '0 0 100%' }
                }}
            >
                <Box sx={{ width: "40%", p: 5 }}>
                    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                        <TextField
                            id="oldPassword"
                            label="Mot de passe actuel"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type={showPasswords.oldPassword ? 'text' : 'password'}
                            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            {...formik.getFieldProps('oldPassword')}
                            InputProps={{
                                endAdornment: (
                                    <Box
                                        sx={{ color: 'action.active', ml: 1, cursor: 'pointer' }}
                                        onClick={() => togglePasswordVisibility('oldPassword')}
                                    >
                                        {showPasswords.oldPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                    </Box>
                                ),
                            }}
                        />
                        <TextField
                            id="newPassword"
                            label="Nouveau mot de passe"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type={showPasswords.newPassword ? 'text' : 'password'}
                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                            {...formik.getFieldProps('newPassword')}
                            InputProps={{
                                endAdornment: (
                                    <Box
                                        sx={{ color: 'action.active', ml: 1, cursor: 'pointer' }}
                                        onClick={() => togglePasswordVisibility('newPassword')}
                                    >
                                        {showPasswords.newPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                    </Box>
                                ),
                            }}
                        />
                        <TextField
                            id="confirmNewPassword"
                            label="Confirmer mot de passe"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type={showPasswords.confirmNewPassword ? 'text' : 'password'}
                            error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                            helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                            {...formik.getFieldProps('confirmNewPassword')}
                            InputProps={{
                                endAdornment: (
                                    <Box
                                        sx={{ color: 'action.active', ml: 1, cursor: 'pointer' }}
                                        onClick={() => togglePasswordVisibility('confirmNewPassword')}
                                    >
                                        {showPasswords.confirmNewPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                    </Box>
                                ),
                            }}
                        />
                        <Button type="submit" variant="contained" color="primary" disabled={fieldsDisabled} fullWidth sx={{ mt: 3, p: 1 }}>
                            Enregistrer
                        </Button>
                    </form>
                </Box>
            </Box>
        </>
    );
};

export default GestionDeMotDePasse;
