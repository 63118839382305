import Api from "../../../services/http/api";

class GrouilleursCtrl {
    static grouilleursData(grouilleursDto, successCallBack, errorCallBack) {
        Api.call(
          grouilleursDto,
          (response) => {
            successCallBack(response);
          },
          () => {
            errorCallBack();
          }
        );
      }
}

export default GrouilleursCtrl