class Offer {
  constructor(
    id,
    boxFirstName,
    boxLastName,
    category,
    subCategory,
    address,
    date,
    business,
    statutGeneral,
    statutSpecifique
  ) {
    this.id = id;
    this.boxFirstName = boxFirstName;
    this.boxLastName = boxLastName;
    this.category = category;
    this.subCategory = subCategory;
    this.address = address;
    this.date = date;
    this.business = business;
    this.statutGeneral = statutGeneral;
    this.statutSpecifique = statutSpecifique;
  }
}

export default Offer;
