class OffreEnCommun {
  constructor(
    id,
    adresse,
    contrat,
    business,
    description,
    disponibilite,
    dateDemarrageSouhaiter,
    sousCategorie,
    montant,
    assistance,
    montantAssistance,
    categorie,
    nombreDePositionnements,
    idGrouilleur,
    nomGrouilleur,
    imgCat,
    dateCreation
  ) {
    this.id = id;
    this.adresse = adresse;
    this.contrat = contrat;
    this.business = business;
    this.description = description;
    this.disponibilite = disponibilite;
    this.dateDemarrageSouhaiter = dateDemarrageSouhaiter;
    this.sousCategorie = sousCategorie;
    this.montant = montant;
    this.assistance = assistance;
    this.montantAssistance = montantAssistance;
    this.categorie = categorie;
    this.nombreDePositionnements = nombreDePositionnements;
    this.idGrouilleur = idGrouilleur;
    this.nomGrouilleur = nomGrouilleur;
    this.imgCat = imgCat;
    this.dateCreation = dateCreation;
  }
}

export default OffreEnCommun;
