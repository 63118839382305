import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Backdrop, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SubcategoryCard from './components/SubcategoryCard';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import EditSubcategoryModal from './components/EditSubcategoryModal';
import AddSubcategoryModal from './components/AddSubcategoryModal';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';

import Api from '../../../../services/http/api';
import CustomToast from '../../../../helpers/customToast';
import subcategorieAddDto from '../../dto/subcategorieAddDto';
import subcategorieDeleteDto from '../../dto/subcategorieDeleteDto';
import subcategorieEditDto from '../../dto/subcategorieEditDto';

const SubcategoryList = ({
  selectedCategory,
  sousFilter,
  handleSousFilterChange,
  sousPage,
  categoriesPerPage,
  handlePreviousSousPage,
  handleNextSousPage,
  renderPageButtons,
  totalSousPages,
  handleSousPageChange,
  fetchCategories
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [editName, setEditName] = useState('');
  const [editLabel, setEditLabel] = useState('');
  const [editPhoto, setEditPhoto] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const openEditModal = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setEditName(subcategory.nom || '');
    setEditLabel(subcategory.libelle || '');
    setEditPhoto(subcategory.photo || '');
    setEditModalOpen(true);
  };

  const openDeleteModal = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setDeleteModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedSubcategory(null);
    setEditName('');
    setEditLabel('');
    setEditPhoto('');
    setEditModalOpen(false);
  };

  const closeDeleteModal = () => {
    setSelectedSubcategory(null);
    setDeleteModalOpen(false);
  };

  const confirmDeleteCategory = () => {
    if (!selectedSubcategory) return;
    closeDeleteModal();
    const toastId = CustomToast.showLoading("Suppression en cours.");
    const dto = new subcategorieDeleteDto(selectedSubcategory.id);
    console.log(dto)
    Api.call(
      dto,
      async (res) => {
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchCategories();
      },
      (err) => {
        console.error("Erreur lors de la suppression :", err);
        CustomToast.hideLoading(toastId);
      }
    );
  };

  const handleAddSubcategory = (newSubcategory) => {
    const toastId = CustomToast.showLoading("Enregistrement en cours.");
    const dto = new subcategorieAddDto(newSubcategory);
    console.log(dto);

    Api.call(
      dto,
      async (res) => {
        console.log(res);
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchCategories();
      },
      (err) => { }
    );
  };

  const handleSubmitCategoryName = async (editedCategory) => {
    const categorie = {
      libelle: editedCategory.libelle,
      libelleSuperCategorie: editedCategory.libelleSuperCategorie.libelle,
      referenceFichier: editedCategory.referenceFichier,
    };

    const toastId = CustomToast.showLoading("Modification en cours.");
    const dto = new subcategorieEditDto(editedCategory.id, categorie);
    console.log(dto);

    Api.call(
      dto,
      async (res) => {
        console.log(res);
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchCategories();
      },
      (err) => { }
    );
  };

  return (
    <Box sx={{ width: "100%", mx: 2, backgroundColor: 'background.paper', p: 2, borderRadius: 2, visibility: selectedCategory ? 'visible' : 'hidden' }}>
      <Backdrop
        sx={{
          color: 'primary.main',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {selectedCategory && (
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, textAlign: 'center' }}>Sous-catégories de {selectedCategory.libelle}</Typography>
          <Box sx={{ display: 'flex' }}>
            <TextField
              label="Filtrer les sous-catégories"
              variant="outlined"
              size="small"
              fullWidth
              value={sousFilter}
              onChange={handleSousFilterChange}
              sx={{ mb: 1 }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ fontSize: '23px', mr: 1 }} />,
              }}
            />
            <Button variant="contained" sx={{ mb: 1, width: "30%", mx: 1 }} onClick={() => setAddModalOpen(true)}>
              Ajouter <AddCircleOutlineIcon sx={{ mx: .5 }} />
            </Button>
          </Box>
          {
            selectedCategory.sousCategories
              .filter(sousCategory =>
              (
                (sousCategory.nom ? sousCategory.nom.toLowerCase() : "").includes(sousFilter.toLowerCase()) ||
                (sousCategory.libelle ? sousCategory.libelle.toLowerCase() : "").includes(sousFilter.toLowerCase())
              )
              )
              .slice((sousPage - 1) * categoriesPerPage, sousPage * categoriesPerPage)
              .map((sousCategory, index) => (
                <SubcategoryCard
                  key={index}
                  selectedCategory={selectedCategory}
                  sousCategory={sousCategory}
                  openEditModal={openEditModal}
                  openDeleteModal={openDeleteModal}
                />
              ))
          }

          <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button onClick={handlePreviousSousPage} disabled={sousPage === 1}>Précédent</Button>
            {renderPageButtons(totalSousPages, sousPage, handleSousPageChange)}
            <Button onClick={handleNextSousPage} disabled={sousPage === totalSousPages}>Suivant</Button>
          </Box>
        </Box>
      )}

      <EditSubcategoryModal
        open={editModalOpen}
        onClose={closeEditModal}
        selectedSubcategory={selectedSubcategory}
        editName={editName}
        setEditName={setEditName}
        editLabel={editLabel}
        setEditLabel={setEditLabel}
        editPhoto={editPhoto}
        setEditPhoto={setEditPhoto}
        handleSubmitCategoryName={handleSubmitCategoryName}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        selectedSubcategory={selectedSubcategory}
        confirmDeleteCategory={confirmDeleteCategory}
      />

      <AddSubcategoryModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        selectedCategory={selectedCategory}
        handleAddSubcategory={handleAddSubcategory}
      />
    </Box>
  );
};

export default SubcategoryList;
