class User {
  constructor(
    adresse,
    bedou,
    caution,
    email,
    expiresIn,
    idBoss,
    idGrouilleur,
    idUtilisateur,
    matricule,
    nom,
    prenom,
    telephone
  ) {
    this.adresse = adresse;
    this.bedou = bedou;
    this.caution = caution;
    this.email = email;
    this.expiresIn = expiresIn;
    this.idBoss = idBoss;
    this.idGrouilleur = idGrouilleur;
    this.idUtilisateur = idUtilisateur;
    this.matricule = matricule;
    this.nom = nom;
    this.prenom = prenom;
    this.telephone = telephone;
  }
}

export default User;
