class LoginDto {
  method = 'POST';
  endpoint = '/api/v3/admin-all/login';
  constructor(login, password) {
    this.data = {
      email: login,
      password: password,
    };
  }
}

export default LoginDto;
