class Positionnement {
  constructor(
    id,
    grouilleurIdUser,
    grouilleurId,
    grouilleurFirstName,
    grouilleurLastName,
    grouilleurPhoneNumber,
    address,
    grouilleurImage,
    montantTravail
  ) {
    this.id = id;
    this.grouilleurIdUser = grouilleurIdUser;
    this.grouilleurId = grouilleurId;
    this.grouilleurFirstName = grouilleurFirstName;
    this.grouilleurLastName = grouilleurLastName;
    this.grouilleurPhoneNumber = grouilleurPhoneNumber;
    this.address = address;
    this.grouilleurImage = grouilleurImage;
    this.grouilleurImage = grouilleurImage;
    this.grouilleurPhoneNumber = grouilleurPhoneNumber;
    this.montantTravail = montantTravail;
  }
}

export default Positionnement;
