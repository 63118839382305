import React, { useEffect, useState } from 'react';
import optionsIcon from '../../../assets/icons/1000002516.svg';
import searchIcon from '../../../assets/icons/searchIcon.svg';
import sendIcon from '../../../assets/icons/sendIcon.svg';
import shareIcon from '../../../assets/icons/shareIcon.svg';
import createIcon from '../../../assets/icons/createIcon.svg';
import '../styles/conversations.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import ConversationsCtrl from '../controllers/get_conversations_ctrl';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

const Messages = () => {
  const height = window.screen.height - 187;

  const [displayDeleteBtn, setDisplayDeleteBtn] = useState(false);

  const [conversations, setConversations] = useState([]);

  const [conversationsIsLoading, setConversationsIsLoading] = useState(true);

  const [selectedConversation, setSelectedConversation] = useState(null);

  const [isMessagesLoading, setMessagesIsLoading] = useState(false);

  const [messages, setMessages] = useState([]);

  const [isSendingInProgress, setIsSendingInProgress] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [newMessageText, setNewMessageText] = useState('');

  const idConversationsToDelete = [];

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const action = event.target.innerText;
    if (action === 'Afficher les messages non lus') {
      const tab = [];
      conversations.forEach((elt) => {
        if (elt.nonLu !== 0) {
          tab.push(elt);
        }
      });
      setConversations(tab);
    } else if (action === 'Supprimer') {
      setDisplayDeleteBtn(true);
    }
  };

  function getConversationsList() {
    ConversationsCtrl.getConversationsList(
      (convs) => {
        setConversations(convs);
        setConversationsIsLoading(false);
      },
      () => {
        setConversationsIsLoading(false);
      }
    );
  }

  function getMessagesList(idGrouilleur) {
    ConversationsCtrl.getMessagesList(
      idGrouilleur,
      (msgs) => {
        setMessages(msgs);
        setMessagesIsLoading(false);
      },
      () => {
        setMessagesIsLoading(false);
      }
    );
  }

  function sendMessage() {
    setIsSendingInProgress(true);
    ConversationsCtrl.sendMessage(
      selectedConversation.idGrouilleur,
      newMessageText,
      (msg) => {
        const msgs = [...messages];
        msgs.push(msg);
        setIsSendingInProgress(false);
        setNewMessageText('');
        setMessages(msgs);
      },
      () => {
        setIsSendingInProgress(false);
      }
    );
  }

  function deleteConversation() {
    if (idConversationsToDelete.length !== 0) {
      handleOpenBackdrop();
      ConversationsCtrl.deleteConversation(
        idConversationsToDelete,
        () => {
          setSelectedConversation(null);
          handleCloseBackdrop();
          setConversationsIsLoading(true);
          getConversationsList();
        },
        () => {
          handleCloseBackdrop();
        }
      );
    }
  }

  function markMessagesRead(messages) {}

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  useEffect(() => {
    getConversationsList();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ width: 400 }}>
          <div
            style={{
              height: displayDeleteBtn ? 238 : 200,
              paddingTop: 70,
              paddingInline: 20,
            }}
          >
            <p style={{ fontSize: 22, fontWeight: 600 }}>Messages</p>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  backgroundColor: 'rgba(181, 186, 181, 0.25)',
                  display: 'flex',
                  padding: 15,
                  marginRight: 14,
                  justifyContent: 'center',
                }}
                onClick={handleClick}
              >
                <img alt="icon" src={optionsIcon} />
              </div>
              <div
                style={{
                  flexGrow: 1,
                  height: 50,
                  borderRadius: 25,
                  backgroundColor: 'rgba(181, 186, 181, 0.25)',
                  display: 'flex',
                  padding: 15,
                }}
              >
                <img alt="icon" src={searchIcon} style={{}} />
                <input
                  className="searchInput"
                  placeholder="Rechercher une conversation"
                />
              </div>
            </div>
            {displayDeleteBtn ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 12,
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginLeft: -9 }}>
                    <Checkbox />
                  </div>
                  <p style={{ marginBottom: 0 }}>Tout selectionner</p>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#FFE4E4',
                    boxShadow: 'none',
                    color: '#EF2E2E',
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#FFE4E4',
                      boxShadow: 'none',
                    },
                  }}
                  startIcon={<DeleteIcon sx={{ color: '#EF2E2E' }} />}
                  onClick={deleteConversation}
                >
                  Supprimer
                </Button>
              </div>
            ) : null}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>
                Afficher les messages non lus
              </MenuItem>
              <MenuItem onClick={handleClose}>Tout marquer comme lu</MenuItem>
              <MenuItem onClick={handleClose} sx={{ color: '#EF2E2E' }}>
                Supprimer
              </MenuItem>
            </Menu>
          </div>
          <div
            style={{
              height: displayDeleteBtn ? height - 238 : height - 200,
              overflowY: 'scroll',
              paddingBottom: 30,
            }}
          >
            {conversationsIsLoading ? (
              <center style={{ marginTop: 20 }}>
                <CircularProgress size={30} />
              </center>
            ) : (
              <div>
                {conversations.map((conversation) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 30,
                      paddingLeft: displayDeleteBtn ? 30 : 0,
                    }}
                  >
                    {displayDeleteBtn ? (
                      <Checkbox
                        onChange={(event) => {
                          const index = idConversationsToDelete.indexOf(
                            conversation.idGrouilleur
                          );
                          if (event.target.checked) {
                            if (index === -1) {
                              idConversationsToDelete.push(
                                conversation.idGrouilleur
                              );
                            }
                          } else {
                            if (index !== -1) {
                              idConversationsToDelete.splice(index, 1);
                            }
                          }
                        }}
                      />
                    ) : null}
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                        marginLeft: displayDeleteBtn ? 0 : 20,
                        marginRight: 20,
                      }}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(181, 186, 181, 0.25)',
                        },
                      }}
                      onClick={() => {
                        setSelectedConversation(conversation);
                        setMessagesIsLoading(true);
                        getMessagesList(conversation.idGrouilleur);
                      }}
                    >
                      <img
                        alt="profile"
                        src={conversation.photoProfile}
                        style={{
                          with: 44,
                          height: 44,
                          borderRadius: 22,
                          marginRight: 10,
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p
                            style={{
                              flexGrow: 1,
                              margin: 0,
                              fontSize: 16,
                              height: 22,
                              marginBottom: 4,
                              overflowY: 'hidden',
                              color:
                                conversation.nonLu === 0
                                  ? '#3D423F'
                                  : '#000000',
                              fontWeight:
                                conversation.nonLu === 0 ? 'initial' : 600,
                            }}
                          >
                            {conversation.emetteur}
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                              color: '#3D423F',
                            }}
                          >
                            {conversation.dateDernierMessage}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              overflowY: 'hidden',
                              height: 20,
                              margin: 0,
                              fontSize: 14,
                              color:
                                conversation.nonLu === 0
                                  ? '#9BA69C'
                                  : '#000000',
                            }}
                          >
                            {conversation.dernierMessage}
                          </p>
                          {conversation.nonLu === 0 ? null : (
                            <div
                              style={{
                                backgroundColor: '#00833D',
                                margin: 0,
                                paddingInline: 4,
                                height: 20,
                                borderRadius: 10,
                                fontSize: 14,
                                color: '#FFF',
                              }}
                            >
                              {conversation.nonLu}
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  </div>
                ))}
              </div>
            )}

            <div
              style={{
                position: 'fixed',
                bottom: 10,
                left: 650,
              }}
            >
              <Fab size="medium" color="primary" aria-label="add">
                <img alt="icon" src={createIcon} />
              </Fab>
            </div>
            <div
              style={{
                position: 'fixed',
                bottom: 10,
              }}
            >
              {displayDeleteBtn ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#E7FFE7',
                    boxShadow: 'none',
                    color: '#00833D',
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    '&:hover': {
                      color: '#FFFFFF',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => {
                    setDisplayDeleteBtn(false);
                  }}
                >
                  Annuler la suppression
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <div
          style={{
            flexGrow: 1,
            height: height,
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: '#B5BAB5',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              height: height - 60,
              paddingTop: 70,
              paddingInline: 30,
            }}
          >
            {selectedConversation !== null ? (
              <div
                style={{
                  height: height - 130,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 30,
                  }}
                >
                  <img
                    alt="profile"
                    src={selectedConversation.photoProfile}
                    style={{
                      with: 44,
                      height: 44,
                      borderRadius: 22,
                      marginRight: 30,
                    }}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <div style={{}}>
                      <p
                        style={{
                          flexGrow: 1,
                          margin: 0,
                          fontSize: 16,
                          color: '#000000',
                          fontWeight: 600,
                        }}
                      >
                        {selectedConversation.emetteur}
                      </p>
                      <p
                        style={{
                          flexGrow: 1,
                          margin: 0,
                          fontSize: 16,
                          color: '#000000',
                        }}
                      >
                        {selectedConversation.emetteur}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        backgroundColor: 'rgba(181, 186, 181, 0.25)',
                        display: 'flex',
                        padding: 15,
                        marginRight: 14,
                        justifyContent: 'center',
                      }}
                    >
                      <img alt="icon" src={searchIcon} style={{}} />
                    </div>
                    <div
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        backgroundColor: 'rgba(181, 186, 181, 0.25)',
                        display: 'flex',
                        padding: 15,
                        marginRight: 14,
                      }}
                    >
                      <img alt="icon" src={shareIcon} />
                    </div>
                  </div>
                </div>
                <div style={{ height: height - 230 }}>
                  {isMessagesLoading ? (
                    <center style={{ marginTop: 100 }}>
                      <CircularProgress size={30} />
                    </center>
                  ) : (
                    <div>
                      {messages.map((msg) => (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent:
                              msg.emetteur === 'Jevebara Admin'
                                ? 'flex-end'
                                : 'flex-start',
                            marginBottom: 20,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor:
                                msg.emetteur === 'Jevebara Admin'
                                  ? '#00833D'
                                  : '#E7FFE7',
                              fontSize: 16,
                              width: 'max-content',
                              maxWidth: 400,
                              color:
                                msg.emetteur === 'Jevebara Admin'
                                  ? '#FFF'
                                  : '#011627',
                              paddingTop: 12,
                              paddingInline: 16,
                              paddingBottom: 12,
                              borderRadius: 12,
                            }}
                          >
                            <p style={{ margin: 0 }}>{msg.message}</p>
                            <p style={{ textAlign: 'end', margin: 0 }}>
                              {msg.date}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              backgroundColor: '#F1F1F1',
              marginInline: 30,
              paddingInline: 16,
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 20,
              display: 'flex',
              position: 'fixed',
              width: window.screen.width - 784,
              bottom: 10,
            }}
          >
            <TextField
              id="outlined-basic"
              placeholder="Messages"
              fullWidth
              multiline
              sx={{ outline: 'none' }}
              variant="standard"
              value={newMessageText}
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(event) => {
                setNewMessageText(event.target.value);
              }}
            />
            {isSendingInProgress ? (
              <CircularProgress size={30} />
            ) : (
              <img
                className="sendBtn"
                alt="icon"
                src={sendIcon}
                value={newMessageText}
                onClick={() => {
                  if (selectedConversation !== null) {
                    sendMessage();
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#00833D', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Messages;
