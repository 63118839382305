// SubcategoryCard.jsx
import React from 'react';
import { Box, Typography, IconButton, CardContent, CardMedia, Divider } from "@mui/material";
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Api from '../../../../../services/http/api';

const SubcategoryCard = ({ selectedCategory, sousCategory, openEditModal, openDeleteModal }) => {
  const category = {
    id: sousCategory.id,
    libelle: sousCategory.libelle,
    libelleSuperCategorie: selectedCategory,
    referenceFichier: sousCategory.referenceFichier,
  }
  return (
    <Box sx={{ display: "flex", mb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h6" component="div">
            {sousCategory.libelle}
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <IconButton
            aria-label="edit-sous-categorie"
            sx={{
              backgroundColor: "#E1FFE0",
              color: "#00833D",
              "&:hover": {
                backgroundColor: "#E1FFE0",
                color: "#00833D",
              },
            }}
            onClick={() => openEditModal(category)}
          >
            <ModeOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label="delete-sous-categorie"
            size="small"
            sx={{
              backgroundColor: "#FFEEEE",
              mx: 2,
              color: "#EF2E2E",
              "&:hover": {
                backgroundColor: "#FFEEEE",
                color: "#EF2E2E",
              },
            }}
            onClick={() => openDeleteModal(category)}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </Box>
        <Divider
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 140, marginLeft: "auto" }}
        image={
          `${Api.baseUrl}/api/file?fileReference=` +
          sousCategory.referenceFichier
        }
        alt={`Image de ${sousCategory.nom}`}
      />
    </Box>
  );
};

export default SubcategoryCard;
