import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Link,

} from "@mui/material";
import React, { useState } from "react";
import Icon from "../../assets/img/screen.png";
import { ResetPasswordButton } from "../../assets/styles/Widgets";
import { useNavigate, useLocation } from "react-router-dom";
import CustomToast from "../../helpers/customToast";

const OtpPage = () => {
  const navigate = useNavigate();
  const otpIdentifiant = useLocation().state.otpId;
  const email = useLocation().state.user;
  const [otp, setOtp] = useState("");

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const goToNewPassword = async () => {
    if (otp.trim().length === 0) {
      CustomToast.showWarnin("Veuillez saisir un code valide");
      return;
    }
    let path = `/new-password/`;
    navigate(path, {
      state: { otpId: otpIdentifiant, user: email, code: otp },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "60%", lg: "50%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <Box sx={{ p: 0 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "24px",
                mb: 0,
                pt: 4,
              }}
            >
              Vérification OTP
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <img src={Icon} alt="Logo" />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: "16px",
                mt: 0,
                p: 0,
              }}
            >
              Nous avons envoyé un code à l’adresse
            </Typography>
            <Typography sx={{ color: "#00833D", pt: 1, textAlign: "center" }}>
              {email}
            </Typography>

            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: "14px",
                color: "#9BA69C",
                my: 3,
                p: 0,
              }}
            >
              
                Cet email n’est pas le votre ?{" "}
                <span>
                  <Link
                    // href="verify"
                    underline="none"
                    onClick={() => navigate(-1)}
                    variant="body2"
                    fontWeight="400"
                    fontSize="16px"
                    color="primary"
                    sx={{ cursor: "pointer" }}
                  >
                    {"Changer"}
                  </Link>
                </span>
            </Typography>

            <Box>
              <FormControl
                sx={{ width: "100%", mt: 1, mb: 2 }}
                variant="outlined"
              >
                <OutlinedInput
                  fullWidth
                  sx={{
                    borderRadius: 1,
                    height: "52px",
                    "& input::placeholder": { fontSize: "14px" },
                    "&:hover $notchedOutline": {
                      borderColor: "primary",
                    },
                  }}
                  id="otp"
                  autoComplete="text"
                  placeholder="Entrez le code"
                  onChange={(event) => handleInputChange(event, setOtp)}
                />
              </FormControl>
              {/* <OTP /> */}
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "400",
                fontSize: "16px",
                color: "#9BA69C",
                mt: 2,
                mb: 0,
                p: 0,
              }}
            >
              Vous n’avez pas reçu de mail ? vérifiez votre boîte de spam{" "}
            </Typography>
            <ResetPasswordButton sx={{ mt: 4 }} onClick={goToNewPassword}>
              Valider
            </ResetPasswordButton>
            <Box sx={{ textAlign: "center", mt: 8 }}>
              <span>
                <strong>
                  <Link
                    // href="verify"
                    underline="none"
                    onClick={() => navigate("/")}
                    variant="body2"
                    fontWeight="400"
                    fontSize="16px"
                    color="#9BA69C"
                    sx={{ cursor: "pointer" }}
                  >
                    {"Revenir à la page de connexion"}
                  </Link>
                </strong>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OtpPage;
