import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Stack,
  Avatar,
  Box,
  Button,
  Typography,
  CircularProgress,
  MenuItem,
  Link,
} from "@mui/material";
import { StyledMenu } from "../../../assets/styles/Widgets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GrouilleursDto from "../dto/grouilleursDto";
import GrouilleursCtrl from "../controllers/grouilleurs_controller";
import PaginationComponent from "../../../components/paginationComponent";
import Api from "../../../services/http/api";
import CustomToast from "../../../helpers/customToast";

const GrouilleursTable = (props) => {
  const searchResult = props.result;
  // console.log(searchResult);
  const advanceSearchResult = props.advanceResult;
  // console.log("Advance search",advanceSearchResult);
  const navigate = useNavigate();
  // const localUserData = useSelector(reducerUserData);
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const [anchorEls, setAnchorEls] = React.useState({});
  const open = Boolean(anchorEls);
  const openStatus = Boolean(anchorElStatus);
  const [urlType, setUrlType] = useState("");
  const size = 50;
  const [loading, setLoading] = useState(true);

  const handleNextPage = async () => {
    if (totalPages === 1) return;
    if (totalPages === pageNumber + 1) return;
    setPageNumber(pageNumber + 1);
    setLoading(true);
    if (searchResult !== null) {
      await props.searchHandler(pageNumber + 1);
    } else if (advanceSearchResult !== null) {
      await props.rechercheAvancerHandler(pageNumber + 1);
    } else {
      await fetchData(urlType, pageNumber, size);
    }
  };

  const handlePreviousPage = async () => {
    if (pageNumber < 1) return;
    setPageNumber(pageNumber - 1);
    console.log(pageNumber);
    setLoading(true);
    if (searchResult !== null) {
      await props.searchHandler(pageNumber - 1);
    } else if (advanceSearchResult !== null) {
      await props.rechercheAvancerHandler(pageNumber - 1);
    } else {
      await fetchData(urlType, pageNumber, size);
    }
  };

  // Update handleClick to set anchor element for specific row
  const handleClick = (event, rowId) => {
    setAnchorEls({ ...anchorEls, [rowId]: event.currentTarget });
  };

  // Update handleClose to clear anchor element for specific row
  const handleClose = (rowId) => {
    setAnchorEls({ ...anchorEls, [rowId]: null });
  };
  const handleChangeStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  const handleClickReload = () => {
    props.reload()
  }
  

  const fetchData = async (urlType, pageNumber, size) => {
    setData(null);
    setLoading(true);
    try {
      let responseData = null;
      let totalPages = 0;

      if (searchResult !== null) {
        responseData = !searchResult.data
          ? searchResult.content
          : searchResult.data;
        totalPages = searchResult.totalPages;
      } else if (advanceSearchResult !== null) {
        responseData = advanceSearchResult.content;
        totalPages = advanceSearchResult.totalPages;
      } else {
        GrouilleursCtrl.grouilleursData(
          new GrouilleursDto(urlType, pageNumber, size),
          (res) => {
            responseData = res.content.content;
            totalPages = res.content.totalPages;
            setData(responseData);
            setTotalPages(totalPages);
          },
          () => {
            console.log("Error Message");
            setLoading(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuItemClick = async (Type) => {
    switch (Type) {
      case "Tout afficher":
        setUrlType("");
        break;
      case "Bloqué":
        setUrlType("actif=false&");
        break;
      case "Actif":
        setUrlType("actif=true&");
        break;
      default:
        setUrlType("");
    }

    await fetchData(urlType, pageNumber, size);
    handleCloseStatus();
  };

  const grouilleurDetails = (userId) => {
    let path = `/home/grouilleur-details/`;
    navigate(path, { state: { data: userId } });
  };

  const activateGrouilleur = async (userId, action) => {
    setData(null);
    setLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/grouilleur/${userId}/${action}`, // Use rowId here
        method: "POST",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      await fetchData(urlType, pageNumber, size);
      CustomToast.showSuccess(response.data.message)
    } catch (error) {
      console.log(error.response);
      CustomToast.showError("Echec");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchResults = (result) => {
    let responseData = null;
    let totalPages = 0;
    setData(null);
    setLoading(true);
    responseData = result.content;
    totalPages = result.totalPages;
    setData(responseData);
    setTotalPages(totalPages);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(urlType, pageNumber, size);
    if (advanceSearchResult !== null) {
      handleSearchResults(advanceSearchResult);
    } else if (searchResult !== null) {
      handleSearchResults(searchResult);
    }
  }, [advanceSearchResult, pageNumber, searchResult, urlType]);

  if (loading || data === null) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "65vh",
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Matricule</TableCell>
              <TableCell>Noms</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Catégorie</TableCell>
              <TableCell>
                <Button
                  id="status-button"
                  aria-controls={openStatus ? "status-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openStatus ? "true" : undefined}
                  variant="outlined"
                  disableRipple
                  elevation={2}
                  onClick={handleChangeStatus}
                  endIcon={
                    <KeyboardArrowDownIcon
                      sx={{ color: "rgba(155, 166, 156, 1)" }}
                    />
                  }
                  sx={{ borderColor: "#F8F8F8", textTransform: "none" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: "#000000",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <Box>Status</Box>
                    </Typography>
                    <Stack direction="row" spacing={1}></Stack>
                  </Box>
                </Button>
                <StyledMenu
                  id="status-menu"
                  MenuListProps={{
                    "aria-labelledby": "status-button",
                  }}
                  anchorEl={anchorElStatus}
                  open={openStatus}
                  onClose={handleCloseStatus}
                >
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleMenuItemClick("Tout afficher")}
                  >
                    Tout afficher
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleMenuItemClick("Bloqué")}
                  >
                    Bloqué{" "}
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleMenuItemClick("Actif")}
                  >
                    Actif
                  </MenuItem>
                </StyledMenu>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Table Rows */}

            {data &&
              data.map((row) => (
                <TableRow key={row.matricule}>
                  <TableCell key={row.matricule}>{row.matricule}</TableCell>
                  <TableCell key={row.nom}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar
                      //  alt="Photo de profile"
                      //  src={`https://apirecette.jevebara.com/api/file?fileReference=${row.photoProfil}`}
                      >
                        {row.nom[0]}
                      </Avatar>
                      <Link
                        onClick={() => grouilleurDetails(row.id)}
                        variant="body2"
                        fontSize="14px"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "black",
                          "&:hover": {
                            fontWeight: "bold",
                            color: "orange",
                          },
                        }}
                      >
                        {row.nom} {row.prenom}
                      </Link>
                    </Stack>
                  </TableCell>

                  <TableCell key={row.telephone}>{row.telephone}</TableCell>
                  <TableCell key={row.adresse}>{row.adresse}</TableCell>

                  <TableCell key={row.souscategorie}>
                    {row.souscategorie === ""
                      ? "Aucune"
                      : // <AddressCell address={row.souscategorie} />
                        row.souscategorie}
                  </TableCell>

                  <TableCell key={row.actif}>
                    <Chip
                      label={row.actif ? "Actif" : "Bloque"}
                      color={row.actif ? "primary" : "error"}
                      size="medium"
                      variant="filled"
                      sx={{ borderRadius: "5px", width: "70px", px: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      id="fade-button"
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(event) => handleClick(event, row.id)} // Pass row id
                    >
                      <Icon icon="pepicons-pencil:dots-y-circle" />
                    </IconButton>

                    <StyledMenu
                      id="status-menu"
                      MenuListProps={{
                        "aria-labelledby": "status-button",
                      }}
                      anchorEl={anchorEls[row.id]} // Use anchor element for specific row
                      open={Boolean(anchorEls[row.id])} // Check if anchor element is set for specific row
                      onClose={() => handleClose(row.id)} // Pass row id
                    >
                      <MenuItem
                        disableRipple
                        sx={{ gap: 1, fontSize: "14px" }}
                        onClick={() => {
                          activateGrouilleur(row.id, "enable");
                          handleClose(row.id);
                        }}
                      >
                        Activer
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        sx={{ gap: 1, fontSize: "14px" }}
                        onClick={() => {
                          activateGrouilleur(row.id, "disable");
                          handleClose(row.id);
                        }}
                      >
                        Desactiver
                      </MenuItem>
                    </StyledMenu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          py: 2,
          px: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <IconButton>
          <Icon
            icon="oi:reload"
            style={{ color: "#787878" }}
            onClick={() => handleClickReload() }
          />
        </IconButton>
        <PaginationComponent
          page={pageNumber}
          totalPage={totalPages}
          onPreviousPage={() => {
            handlePreviousPage();
          }}
          onNextPage={() => {
            handleNextPage();
          }}
        />
      </Box>
    </Paper>
  );
};

export default GrouilleursTable;
