import React, { useState } from "react";
import { Box, Button, Typography, Toolbar } from "@mui/material";
import BaraTable from "../components/BaraTable";
import OffresEnCommun from "../components/offre_commun";
import { AppButton } from "../../../../assets/styles/Widgets";

const Bara = () => {
  const [active, setActive] = useState(true);

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            pt: 3,
            pb: 5,
            gap: 2,
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: "700",
                  fontSize: "22px",
                  m: 0,
                }}
              >
                Liste des offres{" "}
              </Box>
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1.5,
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            <AppButton
              sx={{
                backgroundColor: active ? "green" : "rgba(61, 66, 63, 1)",
                width: { xs: "100%", md: "200px" },
              }}
              onClick={() => {
                setActive(true);
              }}
            >
              Offres
            </AppButton>
            <AppButton
              sx={{
                backgroundColor: !active ? "green" : "rgba(61, 66, 63, 1)",
                width: { xs: "100%", md: "200px" },
              }}
              onClick={() => {
                setActive(false);
              }}
            >
              Mes offres
            </AppButton>
          </Box>
        </Box>
       
      
        {active ? <OffresEnCommun /> : <BaraTable />}
      </Box>
    </>
  );
};

export default Bara;
