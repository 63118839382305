import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  Button,
  Link,
  Stack,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  InputBase,
  LinearProgress,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Backdrop,
  Avatar,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import OfferCtrl from "../../controllers/offer_controller";
import axios from "axios";
import Api from "../../../../services/http/api";
import { useNavigate } from "react-router-dom";
import { TextBox1, TextBox2 } from "../../../../assets/styles/Widgets";
import CustomToast from "../../../../helpers/customToast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BaraDetails = () => {
  const offer = useLocation().state.offer;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingPostionnements, setLoadingPostionnements] = useState(true);
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [positionnements, setPositionnements] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [motif, setMotif] = useState("");
  const [montant, setMontant] = useState(offer.montant);
  const [mode, setMode] = React.useState("CASH");

  console.log("p", positionnements);

  const handleChange = (event) => {
    setMode(event.target.value);
  };

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const handleCloseTerminer = () => {
    setOpen(false);
  };
  const handleOpenTerminer = (item) => {
    setMotif("");
    setSelectedRow(item);
    setOpen(true);
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  function prendreUneOffreEnCharge() {
    handleOpenBackdrop();
    OfferCtrl.prendreOffreEnCharge(
      offer.id,
      () => {
        handleCloseBackdrop();
      },
      () => {
        handleCloseBackdrop();
      }
    );
  }

  function listerLesPositionnements() {
    OfferCtrl.listerLesPositionnementsSurUneOffre(
      offer.id,
      (positionnement) => {
        setPositionnements(positionnement);
        setLoadingPostionnements(false);
      },
      () => {
        setLoadingPostionnements(false);
      }
    );
  }

  function positionnerUnGrouilleurSurUneOffre(grouilleurId, montantTravail) {
    handleOpenBackdrop();
    OfferCtrl.positionnerUnGrouilleurSurUneOffre(
      offer.id,
      grouilleurId,
      montantTravail,
      () => {
        handleCloseBackdrop();
      },
      () => {
        handleCloseBackdrop();
      }
    );
  }

  const grouilleurDetails = async (userId) => {
    let path = `/home/grouilleur-details/`;
    navigate(path, { state: { data: userId } });
  };

  const cancelOffer = async (offerId) => {
    setOpenBackdrop(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/offre/${offerId}/annuler`,
        method: "POST",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log("offer", response);
      navigate(-1);
      CustomToast.showSuccess(response.data.message);
    } catch (error) {
      console.log(error.response);
      CustomToast.showError("Echec");
    } finally {
      setOpenBackdrop(false);
    }
  };

  const terminerOffer = async () => {
    setLoading(true);

    const postData = {
      montant: montant,
      refPaiement: motif,
      typePaiement: mode,
    };
    console.log("Postdata", postData);
    console.log("id", offer.id);

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/offre/${offer.id}/terminer`,
        method: "POST",
        data: postData,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log("terminer", response);

      CustomToast.showSuccess(response.data.message);
      navigate(-1);
    } catch (error) {
      console.log(error);
      CustomToast.showError("Opération Echoué");
    } finally {
      setLoading(false);
      handleCloseTerminer();
      setMode("CASH");
      setMontant(0);
      setMotif("");
    }
  };

  useEffect(() => {
    listerLesPositionnements();
  }, []);

  const OfferButton = (offer, prendreUneOffreEnCharge) => {
    if (offer.statutGeneral === "EN_ATTENTE") {
      return (
        <Button
          sx={{
            textTransform: "none",
            height: "42px",
            width: "200px",
            fontSize: "16px",
            color: "var(--White, #FFF)",
            borderRadius: "6px",
            fontWeight: 400,
            backgroundColor: "#d32f2f",
            "&:hover": {
              backgroundColor: "#FF7900",
              color: "#FFF",
            },
          }}
          onClick={() => {
            cancelOffer(offer.id);
          }}
        >
          Annuler
        </Button>
      );
    } else if (
      offer.statutGeneral === "REFUSER" ||
      offer.statutGeneral === "TERMINER"
    ) {
      return null;
    } else if (!offer.statutGeneral) {
      return null;
    } else {
      return (
        <Button
          sx={{
            textTransform: "none",
            height: "42px",
            width: "200px",
            fontSize: "16px",
            color: "var(--White, #FFF)",
            borderRadius: "6px",
            fontWeight: 400,
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "#FF7900",
              color: "#FFF",
            },
          }}
          onClick={() => {
            prendreUneOffreEnCharge(offer.id);
          }}
        >
          Prendre l'offre
        </Button>
      );
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#00833D", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          <Typography sx={{ fontSize: 22, fontWeight: 600 }}>
            Détails du bara
          </Typography>
          {OfferButton(offer, prendreUneOffreEnCharge)}
        </Box>

        <div
          style={{
            padding: 24,
            backgroundColor: "#fff",
            boxShadow: "0px 4px 4px 0px rgba(139, 139, 139, 0.05)",
          }}
        >
          <div
            style={{
              borderRadius: 5,
              border: "1px solid rgba(181, 186, 181, 1)",
              padding: 16,
              marginBottom: 20,
            }}
          >
            <p style={{ color: "#3D423F", fontSize: 16, fontWeight: 600 }}>
              Détails du bara
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1 }}>
                <p style={{ color: "rgb(61, 66, 63)" }}>Intitulé du bara</p>
                <hr
                  style={{
                    color: "rgba(181, 186, 181, 1)",
                    marginTop: -14,
                    marginBottom: 4,
                  }}
                />
                <p style={{ color: "rgb(61, 66, 63)", fontWeight: 600 }}>
                  {offer.categorie} {offer.sousCategorie}
                </p>
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <p style={{ color: "rgb(61, 66, 63)" }}>
                  Catégorie/Sous catégorie
                </p>
                <hr
                  style={{
                    color: "rgb(181, 186, 181)",
                    marginTop: -14,
                    marginBottom: 4,
                  }}
                />
                <p style={{ color: "rgb(61, 66, 63)", fontWeight: 600 }}>
                  {offer.categorie} / {offer.sousCategorie}
                </p>
              </div>
              <div style={{ flex: 1, textAlign: "end" }}>
                <p style={{ color: "rgb(61, 66, 63)" }}>Localisation du bara</p>
                <hr
                  style={{
                    color: "rgba(181, 186, 181, 1)",
                    marginTop: -14,
                    marginBottom: 4,
                  }}
                />
                <p style={{ color: "rgb(61, 66, 63)", fontWeight: 600 }}>
                  {offer.adresse}
                </p>
              </div>
            </div>
            <p style={{ color: "#000", fontSize: 14, fontWeight: 600 }}>
              Description du bara
            </p>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 3,
                color: "rgba(61, 66, 63)",
                fontWeight: 500,
                padding: 16,
              }}
            >
              {offer.description}
            </div>
          </div>
          <div
            style={{
              borderRadius: 5,
              border: "1px solid rgba(181, 186, 181, 1)",
              padding: 16,
              marginBottom: 20,
            }}
          >
            <p style={{ color: "#000", fontSize: 14, fontWeight: 600 }}>
              Informations sur les positionnements
            </p>
            {loadingPostionnements ? (
              <div
                style={{
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ width: "100%" }}
                  aria-label="custom pagination table"
                >
                  <TableBody>
                    {positionnements.map((p) => (
                      <>
                        <TableRow key={p.id}>
                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                sx={{
                                  height: 40,
                                  width: 40,
                                  bgcolor: "white",
                                }}
                                alt="Photo de profil"
                                src={`${Api.baseUrl}/api/file?fileReference=${p.grouilleurImage}`}
                              />
                              <Link
                                onClick={() =>
                                  grouilleurDetails(p.grouilleurId)
                                }
                                variant="body2"
                                fontSize="16px"
                                sx={{
                                  // mr: 30,
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  color: "black",
                                  "&:hover": {
                                    fontWeight: "bold",
                                    color: "orange",
                                  },
                                }}
                              >
                                {p.grouilleurFirstName} {p.grouilleurLastName}
                              </Link>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            {p.address}
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            {p.grouilleurPhoneNumber}
                          </TableCell>
                          <TableCell>
                            {offer.statutGeneral === "TERMINER" ? null : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "end",
                                  gap: 1,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    backgroundColor: "#FF7900",

                                    "&:hover": {
                                      backgroundColor: "#c46007",
                                      boxShadow: "none",
                                    },
                                  }}
                                  onClick={() => {
                                    positionnerUnGrouilleurSurUneOffre(
                                      p.grouilleurId,
                                      p.montantTravail
                                    );
                                  }}
                                >
                                  Proposer
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    backgroundColor: (theme) =>
                                      theme.palette.primary,
                                    "&:hover": {
                                      backgroundColor: (theme) =>
                                        theme.palette.success.main,
                                      boxShadow: "none",
                                    },
                                  }}
                                  onClick={() => handleOpenTerminer(p)}
                                >
                                  Terminer
                                </Button>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>

          <div style={{ display: "flex" }}></div>
        </div>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseTerminer}
          aria-describedby="alert-dialog-slide-description"
        >
          {loading ? <LinearProgress></LinearProgress> : null}
          <DialogTitle>{"Terminer L'offre ?"}</DialogTitle>
          <DialogContent
            sx={{
              width: 500,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextBox1>Nom du Grouilleur :</TextBox1>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {selectedRow && (
                  <TextBox2>
                    {selectedRow.grouilleurFirstName}{" "}
                    {selectedRow.grouilleurLastName}
                  </TextBox2>
                )}
              </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", gap: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "50%",
                }}
              >
                <TextBox1>Mode de Paiement</TextBox1>
                <FormControl>
                  <Select
                    value={mode}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="CASH">Cash</MenuItem>
                    <MenuItem value="MTN_MONEY">MTN Money</MenuItem>
                    <MenuItem value="INTERNE">Interne</MenuItem>
                    <MenuItem value="ORANGE_MONEY">Orange Money</MenuItem>
                    <MenuItem value="MOOV_MONEY">Moov Money</MenuItem>
                    <MenuItem value="WAVE">Wave</MenuItem>
                    <MenuItem value="PAIEMENT_ELECTRONIQUE">
                      Paiement Electronique
                    </MenuItem>
                    <MenuItem value="CREDIT">Crédit</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "50%",
                }}
              >
                <TextBox1>Montant de la main d'oeuvre</TextBox1>
                <Box
                  sx={{
                    height: 56,
                    borderRadius: 2,
                    backgroundColor: "rgba(181, 186, 181, 0.25)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InputBase
                    id="name"
                    placeholder="Entrez le montant"
                    value={montant}
                    onChange={(event) => handleInputChange(event, setMontant)}
                    sx={{
                      flex: 1,
                      ml: 2,
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextBox1>Reference</TextBox1>
              <Box
                sx={{
                  height: 56,
                  borderRadius: 2,
                  backgroundColor: "rgba(181, 186, 181, 0.25)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputBase
                  id="name"
                  placeholder="Entrez la reference"
                  value={motif}
                  onChange={(event) => handleInputChange(event, setMotif)}
                  sx={{
                    flex: 1,
                    ml: 2,
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCloseTerminer()}
              sx={{ color: "#EF2E2E", textTransform: "none" }}
            >
              Annuler
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => terminerOffer()}
            >
              Terminer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default BaraDetails;
