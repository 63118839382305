import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Link,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Icon from "../../assets/img/lock.png";
import { ResetPasswordButton } from "../../assets/styles/Widgets";
import { useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomToast from "../../helpers/customToast";
import Api from "../../services/http/api";
import axios from "axios";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const otpId = useLocation().state.otpId;
  const email = useLocation().state.user;
  const otp = useLocation().state.code;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const changePassword = async () => {

    if (password.trim().length === 0) {
      CustomToast.showError("Veuillez saisir un mot de passe valide");
      return;
    }
    if (confirmPassword.trim().length === 0) {
      CustomToast.showError("Veuillez confirmer votre mot de passe");
      return;
    }
    if (password !== confirmPassword) {
      CustomToast.showError("Lez mots de passe ne correspondent pas");
      return;
    }

    const postData = {
      email: email,
      code: otp,
      newPassword: password,
      confirmPassword: confirmPassword,
      otpId: otpId,
    };

    

    setOpenBackdrop(true);

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/login/password/reset`,
        method: "POST",
        data: postData,
      });
      console.log(response.data);
      CustomToast.showSuccess(response.data.message);
      navigate("/")
    } catch (error) {
      console.log(error.response);
      CustomToast.showError("Echec");
    } finally {
      setOpenBackdrop(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "60%", lg: "50%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <Box sx={{ p: 0 }}>
            <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "24px",
                  mb: 0,
                  pt: 4,
                }}
              >
                Nouveau mot de passe{" "}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", my: 6 }}>
              <img src={Icon} alt="Logo" />
            </Box>
            <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  mt: 0,
                  p: 0,
                }}
              >
                Définissez un nouveau mot de passe{" "}
            </Typography>

            <Typography 
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  fontSize: "14px",
                  mt: 4,
                }}
              >
                Mot de passe
            </Typography>

            <FormControl
              sx={{ width: "100%", mt: 1, mb: 2 }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{
                  borderRadius: 1,
                  height: "52px",
                  "& input::placeholder": { fontSize: "14px" },
                }}
                id="password"
                type={showPassword ? "text" : "password"}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {/* {showPassword ? <Lock /> : <Lock />} */}
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Votre mot de passe"
                onChange={(event) => handleInputChange(event, setPassword)}
              />
            </FormControl>
            <Typography 
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  fontSize: "14px",
                  mt: 0,
                }}
              >
                Confirmer{" "}
            </Typography>
            <FormControl
              sx={{ width: "100%", mt: 1, mb: 2 }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{
                  borderRadius: 1,
                  height: "52px",
                  "& input::placeholder": { fontSize: "14px" },
                }}
                id="Password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {/* {showPassword ? <Lock /> : <Lock />} */}
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Confirmez le mot de passe"
                onChange={(event) =>
                  handleInputChange(event, setConfirmPassword)
                }
              />
            </FormControl>
            <ResetPasswordButton
              sx={{ mt: 4 }}
              onClick={changePassword}
            >
              Réinitialiser mot de passe
            </ResetPasswordButton>
            <Box sx={{ textAlign: "center", mt: 8 }}>
              <span>
                <strong>
                  <Link
                    // href="verify"
                    underline="none"
                    onClick={() => navigate("/")}
                    variant="body2"
                    fontWeight="400"
                    fontSize="16px"
                    color="#9BA69C"
                    sx={{ cursor: "pointer" }}
                  >
                    {"Revenir à la page de connexion"}
                  </Link>
                </strong>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewPasswordPage;
