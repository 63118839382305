import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
    name: 'userDataSlice',
    initialState: {
        token: null,
        expiresIn: null,
        role: null,
        telephone: null,
        nom: null,
        prenom: null,
        email: null,
        upToDate: false,
        step: 0,
        idBoss: null,
        bedou: 0,
        profiles: "",
        idGrouilleur: null,
        idUtilisateur: null,
        superCategories: [],
        sousCategories: [],
        presentation: "",
        adresse: null,
        caution: 0,
        verified: false
    },
    reducers: {
        deleteLocalUserInfo: (state) => {
            state.token = null;
            state.expiresIn = null;
            state.role = null;
            state.telephone = null;
            state.nom = null;
            state.prenom = null;
            state.email = null;
            state.upToDate = false;
            state.step = 0;
            state.idBoss = null;
            state.bedou = 0;
            state.profiles = "";
            state.idGrouilleur = null;
            state.idUtilisateur = null;
            state.superCategories = [];
            state.sousCategories = [];
            state.presentation = "";
            state.adresse = null;
            state.caution = 0;
            state.verified = false;
        },
        updateLocalUserInfo: (state, action) => {
            if (action.payload == null) {
                state.token = null;
                state.expiresIn = null;
                state.role = null;
                state.telephone = null;
                state.nom = null;
                state.prenom = null;
                state.email = null;
                state.upToDate = false;
                state.step = 0;
                state.idBoss = null;
                state.bedou = 0;
                state.profiles = "";
                state.idGrouilleur = null;
                state.idUtilisateur = null;
                state.superCategories = [];
                state.sousCategories = [];
                state.presentation = "";
                state.adresse = null;
                state.caution = 0;
                state.verified = false;
            } else {
                state.token = action.payload.token;
                state.expiresIn = action.payload.expiresIn;
                state.role = action.payload.role;
                state.telephone = action.payload.telephone;
                state.nom = action.payload.nom;
                state.prenom = action.payload.prenom;
                state.email = action.payload.email;
                state.upToDate = action.payload.upToDate;
                state.step = action.payload.step;
                state.idBoss = action.payload.idBoss;
                state.bedou = action.payload.bedou;
                state.profiles = action.payload.profiles;
                state.idGrouilleur = action.payload.idGrouilleur;
                state.idUtilisateur = action.payload.idUtilisateur;
                state.superCategories = action.payload.sousCategories;
                state.sousCategories = action.payload.sousCategories;
                state.presentation = action.payload.presentation;
                state.adresse = action.payload.adresse;
                state.caution = action.payload.caution;
                state.verified = action.payload.verified;
            }
        },
    }
});

export const { deleteLocalUserInfo, updateLocalUserInfo } = userDataSlice.actions;

export const reducerUserData = (state) => state.userDataSlice;

export default userDataSlice;
