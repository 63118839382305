import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Icon from "../../assets/img/@.png";
import { ResetPasswordButton } from "../../assets/styles/Widgets";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Api from "../../services/http/api";
import CustomToast from "../../helpers/customToast";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const goToOtp = (otpIditifiant) => {
    let path = `/otp/`;
    navigate(path, { state: { otpId: otpIditifiant, user: email } });
  };

  const sendOtp = async () => {
    if (email.trim().length === 0) {
      CustomToast.showWarnin("Veuillez saisir un e-mail valide");
      return;
    }

    const postData = {
      email: email,
    };

    setOpenBackdrop(true);

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/login/otp/send`,
        method: "POST",
        data: postData,
      });
      CustomToast.showSuccess(response.data.message);
      goToOtp(response.data.content.otpId);
    } catch (error) {
      console.log(error.response);
      CustomToast.showError("Echec");
    } finally {
      setOpenBackdrop(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "60%", lg: "50%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <Box sx={{ p: 0 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "24px",
                mb: 0,
              }}
            >
              Réinitialisation du mot de passe{" "}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <img src={Icon} alt="Logo" />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: "16px",
                mt: 0,
                p: 0,
              }}
            >
              Veuillez renseigner l’adresse mail avec laquelle vous avez crée
              votre compte{" "}
            </Typography>

            <Typography
              sx={{
                textAlign: "left",
                fontWeight: "500",
                fontSize: "14px",
                mt: 4,
                p: 0,
              }}
            >
              Votre adresse mail
            </Typography>

            <FormControl
              sx={{ width: "100%", mt: 1, mb: 2 }}
              variant="outlined"
            >
              <OutlinedInput
                fullWidth
                sx={{
                  borderRadius: 1,
                  height: "52px",
                  "& input::placeholder": { fontSize: "14px" },
                  "&:hover $notchedOutline": {
                    borderColor: "primary",
                  },
                }}
                id="email"
                autoComplete="email"
                placeholder="Votre adresse mail"
                onChange={(event) => handleInputChange(event, setEmail)}
              />
            </FormControl>
            <ResetPasswordButton sx={{ mt: 4 }} onClick={sendOtp}>
              Reçevoir le code
            </ResetPasswordButton>
            <Box sx={{ textAlign: "center", mt: 8 }}>
              <span>
                <strong>
                  <Link
                    // href="verify"
                    underline="none"
                    onClick={() => navigate("/")}
                    variant="body2"
                    fontWeight="400"
                    fontSize="16px"
                    color="#9BA69C"
                    sx={{ cursor: "pointer" }}
                  >
                    {"Revenir à la page de connexion"}
                  </Link>
                </strong>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPasswordPage;
