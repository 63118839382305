class Conversation {
  constructor(
    idGrouilleur,
    dateDernierMessage,
    dernierMessage,
    destinataire,
    emetteur,
    nonLu,
    photoProfile
  ) {
    this.idGrouilleur = idGrouilleur;
    this.dateDernierMessage = dateDernierMessage;
    this.dernierMessage = dernierMessage;
    this.destinataire = destinataire;
    this.emetteur = emetteur;
    this.nonLu = nonLu;
    this.photoProfile = photoProfile;
  }
}

export default Conversation;
