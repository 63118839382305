import React, { useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
    Autocomplete,
    TextField,
    CircularProgress
} from "@mui/material";

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const TabRole = () => {
    function createData(
        role,
        administrateur,
        financier,
        manager,
        operationnel
    ) {
        return { role, administrateur, financier, manager, operationnel };
    }

    const rows = [
        createData('Créer un grouilleur', 'oui', 'non', 'oui', 'non'),
        createData('Créer une offre', 'oui', 'oui', 'non', 'non'),
        createData('Créer un boss', 'non', 'non', 'non', 'oui'),
        createData('Valider une trasaction', 'non', 'oui', 'oui', 'oui'),
        createData('Annuler une trasaction', 'oui', 'oui', 'oui', 'non'),
        createData('Attribuer les tâches', 'oui', 'oui', 'non', 'oui'),
        createData('Mise en relation', 'oui', 'non', 'oui', 'non'),
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterRole, setFilterRole] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const filteredRows = rows.filter(row =>
        !filterRole || (row.role && row.role.toLowerCase().includes(filterRole.toLowerCase()))
    );

    


    if (rows.length === 0) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        );
    }

    return (
        <>
            <Box sx={{ marginBottom: 2, display: 'flex', width: '100%', mt: 2 }}>
                <Autocomplete
                    options={['Créer un grouilleur', 'Créer une offre', 'Créer un boss', 'Valider une trasaction', 'Annuler une trasaction', 'Attribuer les tâches', 'Mise en relation']}
                    value={filterRole}
                    size="small"
                    onChange={(event, newValue) => {
                        setFilterRole(newValue);
                    }}
                    sx={{ marginRight: 2, width: "100%" }}
                    renderInput={(params) => <TextField {...params} label="Rôle" variant="outlined" />}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Rôle</TableCell>
                            <TableCell align="center">Administrateur</TableCell>
                            <TableCell align="center">Manager</TableCell>
                            <TableCell align="center">Financier</TableCell>
                            <TableCell align="center">Opérationnel</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.role}>
                                <TableCell component="th" scope="row">
                                    {row.role}
                                </TableCell>
                                <TableCell align="center" sx={{ color: row.administrateur.toLowerCase() === 'oui' ? '#00833D' : 'inherit' }}>{row.administrateur}</TableCell>
                                <TableCell align="center" sx={{ color: row.financier.toLowerCase() === 'oui' ? '#00833D' : 'inherit' }}>{row.financier}</TableCell>
                                <TableCell align="center" sx={{ color: row.manager.toLowerCase() === 'oui' ? '#00833D' : 'inherit' }}>{row.manager}</TableCell>
                                <TableCell align="center" sx={{ color: row.operationnel.toLowerCase() === 'oui' ? '#00833D' : 'inherit' }}>{row.operationnel}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}

export default TabRole;
