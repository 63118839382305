import React from "react";
import { List, ListItem, ListItemText, Divider, Link } from "@mui/material";
import { getCookie } from "cookies-next";

const Sidebar = ({ activeIndex, handleListItemClick }) => {
  const userRole = getCookie("role");
  console.log(userRole);

  return (
    <List
      sx={{
        py: 0,
        width: "100%",
        borderRadius: 2,
        borderColor: "divider",
        backgroundColor: "background.paper",
      }}
    >
      {userRole === "ADMIN" ? (
        <ListItem sx={{ p: 2.5 }}>
          <Link
            href="#"
            underline="none"
            color={activeIndex === 0 ? "primary" : "inherit"}
            onClick={() => handleListItemClick(0)}
          >
            <ListItemText primary="Gestion des utilisateurs" />
          </Link>
        </ListItem>
      ) : null}
      <Divider />
      {userRole === "MANAGER" || userRole === "ADMIN" ? (
        <ListItem sx={{ p: 2.5 }}>
          <Link
            href="#"
            underline="none"
            color={activeIndex === 1 ? "primary" : "inherit"}
            onClick={() => handleListItemClick(1)}
          >
            <ListItemText primary="Gestion des Catégories" />
          </Link>
        </ListItem>
      ) : null}

      <Divider component="li" />
      <ListItem sx={{ p: 2.5 }}>
        <Link
          href="#"
          underline="none"
          color={activeIndex === 2 ? "primary" : "inherit"}
          onClick={() => handleListItemClick(2)}
        >
          <ListItemText primary="Gestion mot de passe" />
        </Link>
      </ListItem>
    </List>
  );
};

export default Sidebar;
