import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Toolbar,
  Divider,
  Avatar,
  MenuItem,
  TextField,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
} from "@mui/material";
import {
  GrouilleurPaper,
  AppButton,
  TextBox1,
  TextBox2,
  StyledMenu,
  ModifiedChip,
} from "../../../assets/styles/Widgets";
import { Icon } from "@iconify/react";
// import Profile from "../../assets/img/profile.png";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Carousel from "react-bootstrap/Carousel";
// import GrouilleurOffreCtrl from "../controllers/grouilleurOffre_controller";
// import GrouilleurOffreDto from "../dto/grouilleurOffreDto";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageZoomInOut from "./ImageZoomInOut";
import CustomToast from "../../../helpers/customToast";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../../assets/styles/Widgets";
import Api from "../../../services/http/api";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Detail = () => {
  const userId = useLocation().state.data;
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [positionement, setPositionement] = useState([]);
  const [anchorElDebit, setAnchorElDebit] = React.useState(null);
  const [anchorElCredit, setAnchorElCredit] = React.useState(null);
  const [bedou, setBedou] = useState("");
  const [credit, setCredit] = useState("");
  const [password, setPassword] = useState("");
  const openDebit = Boolean(anchorElDebit);
  const openCredit = Boolean(anchorElCredit);
  const [openR, setOpenR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slides, setSlides] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const goToMessages = () => {
    navigate(`/home/messages/`);
  };

  const handleImageClick = (image, index) => {
    setOpenR(true);
    setActiveIndex(index);
  };

  const handleDeleteClick = (imageId) => {
    setSelectedImageId(imageId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDelete(selectedImageId);
    setConfirmDialogOpen(false);
    setSelectedImageId(null);
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setSelectedImageId(null);
  };

  const handleChangeBedou = (event) => {
    setBedou(event.target.value);
  };

  const handleChangeCredit = (event) => {
    setCredit(event.target.value);
  };
  const handleChangePassWord = (event) => {
    setPassword(event.target.value);
  };

  const handleClickCredit = (event) => {
    setAnchorElCredit(event.currentTarget);
  };
  const handleClickDebit = (event) => {
    setAnchorElDebit(event.currentTarget);
  };
  const handleCloseDebit = () => {
    setAnchorElDebit(null);
  };
  const handleCloseCredit = () => {
    setAnchorElCredit(null);
  };

  const getBadges = () => {
    const BadgesData = userData.badges;

    const images = BadgesData.flatMap((badge) =>
      badge.fichierJoints && badge.fichierJoints.length > 0
        ? badge.fichierJoints.map((file) => ({
            id: badge.id,
            statut: badge.statut,
            src: `${Api.baseUrl}/api/file?fileReference=${file.reference}`,
          }))
        : []
    );

    setSlides(images);
  };

  const Bedou = async (bedouAction) => {
    if (bedou.trim().length < 2) {
      CustomToast.showWarnin("Veuillez entrer le montant correcte!");
      return;
    } else if (password.trim().length < 4) {
      CustomToast.showWarnin("Veuillez saisir un mot de passe valide");
      return;
    } else {
      setLoading(true);
      setUserData(null);
      const postData = {
        montant: bedou,
        pin: password,
      };
      try {
        await axios({
          url: `${Api.baseUrl}/api/v3/admin-all/finance/bedou/${userData.id}/${bedouAction}`, // Use rowId here
          method: "POST",
          data: postData,
          headers: {
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        await grouilleurDetails(userData.id);
        CustomToast.showSuccess("Opération Reussi !");
        setPassword("");
        setBedou("");
      } catch (error) {
        CustomToast.showError(
          "L'opération a échoué, veuillez signaler si le problème persiste."
        );
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };

  const Caution = async (cautionAction) => {
    if (credit.trim().length < 2) {
      CustomToast.showWarnin("Veuillez entrer le montant correcte!");
      return;
    } else if (password.trim().length < 4) {
      CustomToast.showWarnin("Veuillez saisir un mot de passe valide");
      return;
    } else {
      setLoading(true);
      setUserData(null);
      const postData = {
        montant: credit,
        pin: password,
      };
      try {
        await axios({
          url: `${Api.baseUrl}/api/v3/admin-all/finance/caution/${userData.id}/${cautionAction}`, // Use rowId here
          method: "POST",
          data: postData,
          headers: {
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        await grouilleurDetails(userData.id);
        CustomToast.showSuccess("Opération Reussi !");
        setPassword("");
        setCredit("");
      } catch (error) {
        CustomToast.showError(
          "L'opération a échoué, veuillez signaler si le problème persiste."
        );
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };
  const Badges = async (badgeAction, idBadge) => {
    setLoading(true);
    try {
      await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/badge/admin-all/${badgeAction}/${idBadge}`, // Use rowId here
        method: "POST",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      await grouilleurDetails(userData.id);
      CustomToast.showSuccess("Opération Reussi !");
    } catch (error) {
      CustomToast.showError(
        "L'opération a échoué, veuillez signaler si le problème persiste."
      );
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenimage = (image) => {
    setSelectedImage(`${Api.baseUrl}/api/file?fileReference=${image}`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  const desactivateGrouilleur = async (userId) => {
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/grouilleur/${userId}/disable`, // Use rowId here
        method: "POST",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response);
      console.log("disable");
      navigate("/home/grouilleurs");
    } catch (error) {
      console.log(error.response);
    } finally {
    }
  };

  const handleDelete = async (imageId) => {
    setLoading(true);
    try {
      await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/realisation/${imageId}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      await grouilleurDetails(userData.id);
      CustomToast.showSuccess("Opération Reussi !");
    } catch (error) {
      CustomToast.showError(
        "L'opération a échoué, veuillez signaler si le problème persiste."
      );
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };
  const fetchPositionement = async () => {
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/positionnement/admin-all/grouilleur/${userId}?page=0&size=50`,

        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setPositionement(response.data.content.data);
      console.log("positionnement", response.data.content.data);
    } catch (error) {
      console.error("Error fetching positionement:", error);
    }
  };

  const grouilleurDetails = async () => {
    setLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/grouilleur/admin-all/${userId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log("details", response.data);
      
      setUserData(response.data);
      fetchPositionement();
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    grouilleurDetails();
  }, []);

  const containerStyle = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "600px",
    overflow: "hidden",
    boxSizing: "border-box",
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
  };

  const deleteIconStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "4rem",
    color: "red",
    cursor: "pointer",
    display: "none",
  };

 if (loading || userData === null) {
   return (
     <Box
       sx={{
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
         alignItems: "center",
         height: "70vh",
       }}
     >
       <CircularProgress />
     </Box>
   );
 }
    return (
      <>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                pt: 3,
                // gap: 2,
              }}
            >
              <Grid item xs={12} md={7}>
                <Box sx={{ width: "100%" }}>
                  <GrouilleurPaper
                    elevation={0}
                    square={false}
                    sx={{ display: "flex", flexDirection: "row", px: 1 }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                        justifyContent: "center",
                        // pt: 1,
                      }}
                    >
                      <Grid item xs={12} md={6} lg={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              height: "215px",
                              width: "215px",
                              bgcolor: "white",
                              border: "0.1px solid lightgray",
                              mb: 2,
                            }}
                          >
                            <Avatar
                              sx={{
                                height: "205px",
                                width: "205px",
                                bgcolor: "white",
                                // border: "0.1px solid lightgray",
                              }}
                              alt="Photo de profil"
                              src={`${Api.baseUrl}/api/file?fileReference=${userData.photoProfil}`}
                            />
                          </Avatar>

                          <Box
                            sx={{
                              width: "100%",
                              maxWidth: "250px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "600", mb: 1 }}
                            >
                              {userData.nom} {userData.prenom}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ color: "#00833D", mb: 1 }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: 1,
                                }}
                              >
                                <Box>{userData.telephone}</Box>
                                <Icon
                                  icon="ph:copy-thin"
                                  width="20px"
                                  height="20px"
                                />
                              </Box>
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "500", mb: 1 }}
                            >
                              Matricule: {userData.matricule}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "400", mb: 4 }}
                            >
                              Crée le {formatDate(userData.dateCreation)}
                            </Typography>
                            <AppButton
                              startIcon={
                                <Icon
                                  icon="ep:chat-dot-round"
                                  width="25px"
                                  height="25px"
                                  style={{ color: "white" }}
                                />
                              }
                              sx={{ background: "#00833D", width: "100%" }}
                              onClick={goToMessages}
                            >
                              Message
                            </AppButton>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={8}>
                        <Box
                          sx={{
                            px: 3,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "700", mb: 2 }}
                          >
                            Informations basiques
                          </Typography>
                          <Box>
                            <TextBox1>Email</TextBox1>
                            <TextBox2
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Box>{userData.email}</Box>
                              <Icon
                                icon="ph:copy-thin"
                                width="20px"
                                height="20px"
                              />
                            </TextBox2>
                            <Box
                              sx={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: userData.actif ? "#00833D" : "#FF2400",
                                mb: 1,
                              }}
                            >
                              {userData.actif ? "ACTIF" : "DESACTIVER"}
                            </Box>
                            <TextBox1>Localisation</TextBox1>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              {userData.adresse}
                            </Typography>
                            <TextBox1>Présentation</TextBox1>
                            <Box
                              sx={{
                                maxHeight: "130px",
                                overflowY: "auto",
                                scrollbarWidth: "thin",
                                "&::-webkit-scrollbar": {
                                  width: "5px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: "#f1f1f1",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#888",
                                  borderRadius: "5px",
                                },
                              }}
                            >
                              {userData.presentation}
                            </Box>
                          </Box>
                          <Divider sx={{ my: 2 }} />
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "700", mb: 2 }}
                            >
                              Informations financières
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                <TextBox1>Cagnote</TextBox1>
                                <TextBox1>Crédit Formation</TextBox1>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                <ModifiedChip label={userData.cagnote} />
                                <ModifiedChip label={userData.caution} />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                <IconButton
                                  id="bedou-button"
                                  aria-controls={
                                    openDebit ? "bedou-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={openDebit ? "true" : undefined}
                                  onClick={handleClickDebit}
                                >
                                  <Icon icon="pepicons-pencil:dots-y-circle" />
                                </IconButton>
                                <IconButton
                                  id="credit-button"
                                  aria-controls={
                                    openCredit ? "credit-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    openCredit ? "true" : undefined
                                  }
                                  onClick={handleClickCredit}
                                >
                                  <Icon icon="pepicons-pencil:dots-y-circle" />
                                </IconButton>
                              </Box>
                            </Box>

                            <StyledMenu
                              id="credit-menu"
                              MenuListProps={{
                                "aria-labelledby": "Credit-menu",
                              }}
                              anchorEl={anchorElCredit}
                              open={openCredit}
                              onClose={handleCloseCredit}
                            >
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <TextBox1>Crédit Formation</TextBox1>
                              </MenuItem>
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <TextField
                                  id="text-field-credit"
                                  label="Entrer la somme"
                                  variant="outlined"
                                  margin="normal"
                                  type="number"
                                  fullWidth
                                  value={credit}
                                  onChange={handleChangeCredit}
                                />
                              </MenuItem>
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <TextField
                                  id="text-field-mdp"
                                  label="Mot de passe"
                                  variant="outlined"
                                  type="password"
                                  margin="normal"
                                  fullWidth
                                  value={password}
                                  onChange={handleChangePassWord}
                                />
                              </MenuItem>
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <AppButton
                                  sx={{
                                    background: "#00833D",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    Caution("credit");
                                    handleCloseCredit();
                                  }}
                                >
                                  Créditer
                                </AppButton>
                                <AppButton
                                  sx={{
                                    background: "#00833D",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    Caution("debit");
                                    handleCloseCredit();
                                  }}
                                >
                                  Débiter
                                </AppButton>
                              </MenuItem>
                            </StyledMenu>

                            <StyledMenu
                              id="bedou-menu"
                              MenuListProps={{
                                "aria-labelledby": "bedou-menu",
                              }}
                              anchorEl={anchorElDebit}
                              open={openDebit}
                              onClose={handleCloseDebit}
                            >
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <TextBox1>Bedou</TextBox1>
                              </MenuItem>
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <TextField
                                  id="text-field-debit"
                                  label="Entrer la somme"
                                  variant="outlined"
                                  margin="normal"
                                  type="number"
                                  fullWidth
                                  value={bedou}
                                  onChange={handleChangeBedou}
                                />
                              </MenuItem>
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <TextField
                                  id="text-field-mdp"
                                  label="Mot de passe"
                                  variant="outlined"
                                  type="password"
                                  margin="normal"
                                  fullWidth
                                  value={password}
                                  onChange={handleChangePassWord}
                                />
                              </MenuItem>
                              <MenuItem disableRipple sx={{ gap: 1, mx: 1 }}>
                                <AppButton
                                  sx={{
                                    background: "#00833D",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    Bedou("credit");
                                    handleCloseDebit();
                                  }}
                                >
                                  Créditer
                                </AppButton>
                                <AppButton
                                  sx={{
                                    background: "#00833D",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    Bedou("debit");
                                    handleCloseDebit();
                                  }}
                                >
                                  Débiter
                                </AppButton>
                              </MenuItem>
                            </StyledMenu>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </GrouilleurPaper>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box sx={{ width: "100%" }}>
                  <GrouilleurPaper elevation={0} square={false} sx={{ px: 0 }}>
                    <Box
                      sx={{
                        borderWidth: "0 0 0 10px",
                        borderStyle: "solid",
                        borderRadius: " 10px",
                        borderColor: "rgba(0, 131, 61, 1)",
                        px: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "700", mb: 2 }}
                      >
                        Pièces justificatives
                      </Typography>
                      <Box>
                        <ImageList
                          sx={{ width: "100%", height: 450 }}
                          cols={isMobile ? 1 : 2}
                        >
                          {userData.badges.length === 0 ? (
                            <p>Aucun Document trouvé</p>
                          ) : (
                            userData.badges.map((badge) =>
                              badge.fichierJoints.length === 0 ? (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    my: 4,
                                  }}
                                >
                                  <HighlightOffIcon
                                    fontSize="large"
                                    sx={{ color: "#f44336" }}
                                  />
                                  <Typography>
                                    Aucun fichier joints trouvé
                                  </Typography>
                                </Box>
                              ) : (
                                <ImageListItem key={badge.id}>
                                  <>
                                    {badge.fichierJoints.map((file) => (
                                      <>
                                        <Card sx={{ width: "100%" }}>
                                          <CardActionArea
                                            onClick={() => {
                                              handleOpenimage(file.reference);
                                              getBadges();
                                            }}
                                          >
                                            <CardMedia
                                              component="img"
                                              height="460"
                                              image={`${Api.baseUrl}/api/file?fileReference=${file.reference}`}
                                              alt={file.reference}
                                            />
                                          </CardActionArea>
                                        </Card>

                                        <Dialog
                                          fullScreen
                                          open={open}
                                          onClose={() => setOpen(false)}
                                          // maxWidth="lg"
                                          // sx={{ height:"99%"}}
                                        >
                                          <Toolbar sx={{ bgcolor: "#202124" }}>
                                            <Box
                                              sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row-reverse",
                                              }}
                                            >
                                              <IconButton
                                                edge="end"
                                                // color="inherit"
                                                onClick={handleClose}
                                                aria-label="close"
                                              >
                                                <CloseIcon
                                                  sx={{ color: "#FFF" }}
                                                />
                                              </IconButton>
                                            </Box>
                                          </Toolbar>
                                          <DialogContent
                                            sx={{ bgcolor: "#202124" }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 2,
                                                bgcolor: "#202124",
                                              }}
                                            >
                                              {selectedImage && (
                                                <ImageZoomInOut
                                                  imageUrl={selectedImage}
                                                  data={slides}
                                                  badgeAction={Badges}
                                                  setOpen={setOpen}
                                                />
                                              )}
                                            </Box>
                                          </DialogContent>
                                        </Dialog>
                                      </>
                                    ))}
                                  </>
                                  <ImageListItemBar title={badge.typeBadge} />
                                </ImageListItem>
                              )
                            )
                          )}
                        </ImageList>
                      </Box>
                    </Box>
                  </GrouilleurPaper>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              fontWeight: "700",
              fontSize: "24px",
              m: 0,
              pt: 6,
            }}
          >
            Realisations du grouilleur
          </Box>
          <Box sx={{ placeItems: "center", mt: 4, width: "100%" }}>
            <Box>
              <Carousel
                data-bs-theme="dark"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                {userData.realisations.map((image, index) => (
                  <Carousel.Item interval={1500} key={image.imgPath}>
                    <div
                      style={containerStyle}
                      onMouseEnter={(e) => {
                        e.currentTarget.children[0].style.filter = "blur(5px)";
                        e.currentTarget.children[1].style.display = "block";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.children[0].style.filter = "none";
                        e.currentTarget.children[1].style.display = "none";
                      }}
                      onClick={() => handleImageClick(image, index)}
                    >
                      <img
                        src={`${Api.baseUrl}/api/file?fileReference=${image.referenceFichier}`}
                        alt={image.referenceFichier}
                        style={imageStyle}
                      />
                      <Icon
                        icon="material-symbols:delete"
                        style={deleteIconStyle}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the full screen on delete icon click
                          handleDeleteClick(image.id);
                        }}
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Box>
            <Dialog
              open={openR}
              onClose={() => setOpenR(false)}
              maxWidth="lg"
              fullScreen
            >
              <Toolbar sx={{ bgcolor: "#202124" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <IconButton
                    edge="end"
                    onClick={() => setOpenR(false)}
                    aria-label="close"
                  >
                    <CloseIcon sx={{ color: "#FFF" }} />
                  </IconButton>
                </Box>
              </Toolbar>
              <DialogContent sx={{ bgcolor: "#202124" }}>
                <Carousel
                  activeIndex={activeIndex}
                  onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
                  style={{
                    maxWidth: "100%",
                    height: "95%",
                    overflow: "hidden",
                  }}
                >
                  {userData.realisations.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        src={`${Api.baseUrl}/api/file?fileReference=${image.referenceFichier}`}
                        alt={image.referenceFichier}
                        style={{
                          width: "100%",
                          height: "100vh",
                          objectFit: "contain",
                        }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </DialogContent>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
              <DialogTitle>Attention</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Voulez vous vraiment supprimer cette Realisation?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                  Confirmer
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          <Box
            sx={{
              fontWeight: "700",
              fontSize: "24px",
              m: 0,
              pt: 6,
            }}
          >
            Positionnements du grouilleur
          </Box>
          <Box sx={{ py: 2 }}>
            {positionement.length === 0 ? (
              <Box
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                  m: 0,
                }}
              >
                Aucun positionement
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date de Creation</StyledTableCell>
                      <StyledTableCell>Commission Grouilleur</StyledTableCell>
                      <StyledTableCell>Commission Boss</StyledTableCell>
                      <StyledTableCell>Montant du Travail</StyledTableCell>
                      <StyledTableCell>Statut</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {positionement.map((row) => (
                      <TableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {formatDate(row.dateCreation)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.montantCommissionGrouilleur}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.montantCommissionBoss}
                        </StyledTableCell>
                        <StyledTableCell>{row.montantTravail}</StyledTableCell>
                        <StyledTableCell>
                          {/* <Chip
                          label={row.actif ? "Terminé" : "En cours"}
                          color={row.actif ? "primary" : "error"}
                          size="medium"
                          variant="filled"
                          sx={{ borderRadius: "5px", width: "80px", px: 0 }}
                        /> */}
                          {row.statutGeneral}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>

          {/* <Box
          sx={{
            fontWeight: "700",
            fontSize: "24px",
            m: 0,
            pt: 4,
          }}
        >
          Historique des blocages et déblocages
        </Box> */}
          {/* <Box sx={{ py: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Activité</TableCell>
                  <TableCell>Auteur</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Motif</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historique.map((row) => (
                  <TableRow key={row.activite}>
                    <StyledTableCell component="th" scope="row">
                      {row.activite}
                    </StyledTableCell>
                    <StyledTableCell>{row.auteur}</StyledTableCell>
                    <StyledTableCell>{row.date}</StyledTableCell>
                    <StyledTableCell>{row.motif}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box> */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                gap: 1.5,
                justifyContent: "start",
              }}
            ></Box>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                gap: 1.5,
                justifyContent: "end",
              }}
            >
              <AppButton
                startIcon={
                  <Icon icon="uil:file-alt" style={{ color: "white" }} />
                }
                sx={{ background: "#FF8888" }}
                onClick={() => desactivateGrouilleur(userData.id)}
              >
                Bloquer
              </AppButton>
            </Box>
          </Box>
        </Box>
      </>
    );
};

export default Detail;
