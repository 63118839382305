import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Checkbox, Alert, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dropzone from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import axios from 'axios';
import CustomToast from '../../../../../helpers/customToast';
import Api from '../../../../../services/http/api';

const CategoryAddModal = ({ open, onClose, handleAddCategory }) => {
  const [newCategoryLabel, setNewCategoryLabel] = useState('');
  const [newCategoryFileReference, setNewCategoryFileReference] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [error, setError] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleSubmit = () => {
    if (newCategoryLabel && newCategoryFileReference) {
      const newCategory = {
        libelle: newCategoryLabel,
        referenceFichier: newCategoryFileReference,
        disponible: isAvailable
      };
      handleAddCategory(newCategory);
      setNewCategoryLabel('');
      setNewCategoryFileReference(null);
      setIsAvailable(false);
      onClose();
    }
  };

  const handleDrop = async (acceptedFiles) => {
    const validExtensions = ['.png', '.jpg', '.jpeg'];
    const file = acceptedFiles[0];
    const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

    if (!validExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      setError('Extensions acceptees (.PNG, .JPG ou .JPEG).');
      return;
    }
    const token = sessionStorage.getItem("token");

    try {
      const toastId = CustomToast.showLoading("fichier en cours Importation.");
      const formData = new FormData();
      formData.append('file', file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      };
      const response = await axios.post(
        `${Api.baseUrl}m/api/v3/admin-all/file`,
        formData,
        config
      );
      console.log('Réponse du serveur :', response.data.content.path);
      setNewCategoryFileReference(response.data.content.path)
      CustomToast.hideLoading(toastId);
      CustomToast.showSuccess("Image importeé avec succès");
    } catch (error) {
      CustomToast.showError("Impossible d'importer cette image.");
    }
    setError('');
  };
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={{ width: 500, p: 3, mx: 'auto', mt: '10%', backgroundColor: 'background.paper', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" component="h2" sx={{ flexGrow: 1, fontWeight: 'bold', textAlign: 'center' }}>
              Ajouter une nouvelle catégorie
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label="Libellé de la catégorie"
            variant="outlined"
            value={newCategoryLabel}
            onChange={(e) => setNewCategoryLabel(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Checkbox
              color="success"
              checked={isAvailable}
              onChange={(e) => setIsAvailable(e.target.checked)}
            />
            <Typography sx={{ mt: 0.5 }}>Disponible ?</Typography>
          </Box>
          {error && <Alert severity="error" sx={{ m: .5 }}>{error}</Alert>}
          <Dropzone onDrop={handleDrop} accept={[".png", ".jpg", ".jpeg"]}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}
                  style={{
                    border: '1px solid #CECECE',
                    height: 150,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px',
                    backgroundColor: '#F8F8F8',
                    cursor: 'pointer'
                  }}>
                  <input {...getInputProps()} />
                  <CloudUploadOutlinedIcon sx={{ fontSize: '80px', color: '#C9C9C9' }} />
                  <Typography>
                    {newCategoryFileReference ? 'Image sélectionnée avec succès' : 'Sélectionnez ou déposez une image'}
                  </Typography>
                </div>
              </section>
            )}
          </Dropzone>

          <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
            Ajouter
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default CategoryAddModal;
