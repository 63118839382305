import React from "react";
import {
  Box,
  Toolbar,
  Typography,
  MenuItem,
  // Dialog,
  // DialogActions,
  // FormControl,
  // DialogTitle,
  // IconButton,
  // DialogContent,
  // OutlinedInput,
  // InputAdornment,
} from "@mui/material";
import { AppButton, StyledMenu  } from "../../../assets/styles/Widgets";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import HomeTable from "./CorbeilleTable";

const CorbeilleDeTaches = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
    >
      <Toolbar />
      <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pt: 3,
            pb: 10,
          }}
        >
          <Box sx={{ width: "40%" }}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "22px",
                  m: 0,
                }}
              >
                Corbeille de tâches{" "}
              </Box>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              gap: 1.5,
              justifyContent: "end",
            }}
          >
            {/* <FormControl sx={{ width: "50%", ml: 3 }} variant="outlined">
            <OutlinedInput
              sx={{
                borderRadius: 1,
                height: "42px",
                "& input::placeholder": { fontSize: "14px" },
                "&:hover": {
                  bordercolor: "primary",
                },
              }}
              id="search"
              autoComplete="email"
              placeholder="Rechercher..."
              onChange={(event) => handleInputChange(event, setParams)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search"
                    onClick={searchGrouilleur}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl> */}
            <AppButton
              id="advance-search-button"
              aria-controls={open ? "advance-search-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              endIcon={<Icon icon="lucide:filter" style={{ color: "white" }} />}
              sx={{ background: "rgba(0, 8, 12, 1)" }}
            >
              Filtrer par
            </AppButton>
            <StyledMenu
              id="advance-search-menu"
              MenuListProps={{
                "aria-labelledby": "advance-search-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem  disableRipple sx={{ gap: 1 }}>
                Déconnection
              </MenuItem>
            </StyledMenu>
            <AppButton
              // startIcon={
              //   <Icon icon="uil:file-alt" style={{ color: "white" }} />
              // }
              sx={{ background: "rgba(61, 66, 63, 1)" }}
            >
              Mes tâches
            </AppButton>
          </Box>
        </Box>
        <HomeTable />
    
    </Box>
  );
};

export default CorbeilleDeTaches;
