import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Stack,
  Avatar,
  Box,
  Button,
  Typography,
  // Menu,
  // Fade,
  MenuItem,
} from "@mui/material";
import { AppButton, HomeTableButton, StyledMenu } from "../../../assets/styles/Widgets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Icon } from "@iconify/react";

// const columns = [
//   { id: "column1", label: "Column 1" },
//   { id: "column2", label: "Column 2" },
//   { id: "column3", label: "Column 3" },
//   { id: "column4", label: "Column 4" },
//   { id: "column5", label: "Column 5" },
// ];

const data = [
  {
    id: 1,
    column1: "Arnaud D Larage a crée une nouvelle offre",
    column2: "Boss",
    column3: "Marshall Borer",
    column4: "427-883-1681",
    statut: "En cours",
  },
  {
    id: 2,
    column1: "Minko Motojimo a uploader une pièce d’identité",
    column2: "Grouilleur",
    column3: "William Dickinson",
    column4: "427-883-1681",
    statut: "En cours",
  },
  {
    id: 3,
    column1: "Arnaud D Larage a crée une nouvelle offre",
    column2: "Grouilleur",
    column3: "Ella O'Conner",
    column4: "242-601-1506",
    statut: "Non traitée",
  },
  {
    id: 4,
    column1: "Arnaud D Larage a crée une nouvelle offre",
    column2: "Boss",
    column3: "Ella O'Conner",
    column4: "735-254-1355",
    statut: "Non traitée",
  },
  // Add more data as needed
];
// console.log(data.map(item => item.statut)
// );

const HomeTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const open = Boolean(anchorEl);
  const openStatus = Boolean(anchorElStatus);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tâches </TableCell>
            <TableCell>Profil</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell></TableCell>
            {/* <TableCell>
              <Button
                id="status-button"
                aria-controls={openStatus ? "status-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openStatus ? "true" : undefined}
                variant="outlined"
                disableRipple
                elevation={2}
                onClick={handleChangeStatus}
                endIcon={
                  <KeyboardArrowDownIcon
                    sx={{ color: "rgba(155, 166, 156, 1)" }}
                  />
                }
                sx={{ borderColor: "#F8F8F8", textTransform: "none" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <Box>Status</Box>
                  </Typography>
                  <Stack direction="row" spacing={1}></Stack>
                </Box>
              </Button>
              <StyledMenu
                id="status-menu"
                MenuListProps={{
                  "aria-labelledby": "status-button",
                }}
                anchorEl={anchorElStatus}
                open={openStatus}
                onClose={handleCloseStatus}
              >
                <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }}>
                  Tout afficher
                </MenuItem>
                <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }}>
                  Bloqué{" "}
                </MenuItem>
                <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }}>
                  Actif
                </MenuItem>
              </StyledMenu>
            </TableCell>
            <TableCell>Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Table Rows */}
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row) => (
            <TableRow>
              <TableCell key={row.column1}>{row.column1}</TableCell>
              <TableCell key={row.column2}>{row.column2}</TableCell>
              <TableCell key={row.statut}>{row.statut}</TableCell>

              {/* <TableCell>
                  <Chip
                    label={row.statut}
                    color={row.statut === "Actif" ? "primary" : "error"}
                    size="medium"
                    variant="filled"
                    sx={{ borderRadius: "5px", width: "72px" }}
                  />
                </TableCell> */}
              <TableCell sx={{ display: "flex", gap:2 }}>
                  <Avatar
                      sx={{
                        background: "#F8F8F8",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                     <IconButton
                  >
                    <Icon icon="material-symbols-light:lock-outline"  style={{color: "#787878"}} />
                    
                  </IconButton>
                    </Avatar>
                  <Avatar
                      sx={{
                        background: "#F8F8F8",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                     <IconButton
                    // id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Icon icon="tabler:dots"  style={{color: "#787878"}} />
                    
                  </IconButton>
                    </Avatar>

                  <StyledMenu
                    id="status-menu"
                    MenuListProps={{
                      "aria-labelledby": "status-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }} onClick={handleClose} >
                      Activer{" "}
                    </MenuItem>
                    <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }} onClick={handleClose}>
                      Desactiver{" "}
                    </MenuItem>
                    <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }} onClick={handleClose}>
                      Blocker
                    </MenuItem>
                  </StyledMenu>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {/* Table Footer - Pagination */}
        {/* <TableFooter sx={{ justifyContent: "center" }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              colSpan={4} // Number of columns in the table
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ paddingTop: 10, paddingBottom: 10 }}
              labelRowsPerPage="Lignes par page:"
            />
          </TableRow>
        </TableFooter> */}
      </Table>
    </TableContainer>
  );
};

export default HomeTable;
