import { useEffect, useState } from "react";
import OfferCtrl from "../../controllers/offer_controller";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  MenuItem,
  CircularProgress,
  Typography,
  Stack,
  Badge,
  Chip,
} from "@mui/material";
import { StyledMenu, formatDate } from "../../../../assets/styles/Widgets";
import PaginationComponent from "../../../../components/paginationComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Icon } from "@iconify/react";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import OffresEnCommunDto from "../../dto/offres_en_commun";
import { getCookie } from "cookies-next";
import OffresEnCommunAdminDto from "../../dto/offres_en_commun_admin";
import CustomToast from "../../../../helpers/customToast";
import axios from "axios";
import Api from "../../../../services/http/api";

function OffresEnCommun() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const openStatus = Boolean(anchorElStatus);
  const [status, setStatus] = useState("");
  const role = getCookie("role");

  const handleNextPage = async () => {
    if (totalPages === 1) return;
    if (totalPages === page + 1) return;
    setPage(page + 1);
    setIsLoading(true);

    await fetchData(page, status);
  };

  const handlePreviousPage = async () => {
    if (page < 1) return;
    setPage(page - 1);
    setIsLoading(true);
    await fetchData(page, status);
  };

  const fetchData = async (page, status) => {
    setData(null);
    setIsLoading(true);
    try {
      let responseData = null;
      let totalPages = 0;

      OfferCtrl.offresCommuns(
        role === "ADMIN"
          ? new OffresEnCommunAdminDto(page, status)
          : new OffresEnCommunDto(page, status),
        (res) => {
          // console.log(res.content);
          responseData = res.content.content;
          totalPages = res.content.totalPages;
          setData(responseData);
          setTotalPages(totalPages);
        },
        () => {
          console.log("Error Message");
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // function prendreUneOffreEnCharge(offreId) {
  //   handleOpenBackdrop();
  //   OfferCtrl.prendreOffreEnCharge(
  //     offreId,
  //     () => {
  //       getOffers(currentPage, currentOfferStatus);
  //       handleCloseBackdrop();
  //     },
  //     () => {
  //       handleCloseBackdrop();
  //     }
  //   );
  // }

  const goToDetails = (offer) => {
    let path = "/home/bara-details/";
    navigate(path, { state: { offer: offer } });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  const handleClickMenu = async (todo) => {
    let newStatus;
    switch (todo) {
      case "En Cours de traitement":
        newStatus = "EN_ATTENTE";
        break;
      case "Nouveau":
        newStatus = "NOUVEAU";
        break;
      case "Terminé":
        newStatus = "TERMINER";
        break;
      case "Refusé":
        newStatus = "REFUSER";
        break;
      case "Annulé":
        newStatus = "ANNULER";
        break;
      case "TOUT":
        newStatus = "";
        break;
      default:
        newStatus = status;
    }

    setStatus(newStatus);
    await fetchData(page, newStatus);
    handleCloseStatus();
  };

  const displayStatus = (value) => {
    let accent;
    let label;
    switch (value) {
      case "EN_ATTENTE":
        accent = "warning";
        label = "En cour de traitement";
        break;
      case "NOUVEAU":
        accent = "info";
        label = "Nouveau";
        break;
      case "TERMINER":
        accent = "success";
        label = "Terminé";
        break;
      case "REFUSER":
        accent = "error";
        label = "Refusé";
        break;
      case "ANNULER":
        accent = "error";
        label = "Annulé";
        break;
      default:
        accent = "success";
        label = "Terminer";
    }

    // const badge = <Badge color={accent} badgeContent=""></Badge>;
    const chip = <Chip color={accent} label={label} />;
    return chip;
  };

  function prendreUneOffreEnCharge(offer) {
    handleOpenBackdrop();
    OfferCtrl.prendreOffreEnCharge(
      offer.id,
      () => {
        fetchData(page, status);
        handleCloseBackdrop();
      },
      () => {
        handleCloseBackdrop();
      }
    );
  }
  const validateOffer = async (offerId, action) => {
    setData(null);
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/offre/${offerId}/${action}`, // Use rowId here
        method: "POST",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response);

      CustomToast.showSuccess(response.data.message);
    } catch (error) {
      console.log(error.response);
      CustomToast.showError("Echec");
    } finally {
      await fetchData(page, status);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, status);
  }, [page, status]);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: window.screen.height - 430,
          boxShadow:
            "0px 1px 0px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      >
        {isLoading || data === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom du boss</TableCell>
                <TableCell>Sous catégorie</TableCell>
                <TableCell>Telephone</TableCell>
                {role === "ADMIN" ? <TableCell>Gestionnaire</TableCell> : null}
                <TableCell>
                  <Icon icon="heroicons:map-pin" width="20px" height="14px" />{" "}
                  Adresse bara
                </TableCell>
                <TableCell>Date besoin</TableCell>
                <TableCell>
                  <Button
                    id="status-button"
                    aria-controls={openStatus ? "status-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openStatus ? "true" : undefined}
                    variant="outlined"
                    disableRipple
                    elevation={2}
                    onClick={handleChangeStatus}
                    endIcon={
                      <KeyboardArrowDownIcon
                        sx={{ color: "rgba(155, 166, 156, 1)" }}
                      />
                    }
                    sx={{ borderColor: "#F8F8F8", textTransform: "none" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        <Box>Status</Box>
                      </Typography>
                      <Stack direction="row" spacing={1}></Stack>
                    </Box>
                  </Button>
                  <StyledMenu
                    id="status-menu"
                    MenuListProps={{
                      "aria-labelledby": "status-button",
                    }}
                    anchorEl={anchorElStatus}
                    open={openStatus}
                    onClose={handleCloseStatus}
                  >
                    <MenuItem
                      disableRipple
                      sx={{ gap: 1, fontSize: "14px" }}
                      onClick={() => handleClickMenu("Tout")}
                    >
                      Tout
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ gap: 1, fontSize: "14px" }}
                      onClick={() => handleClickMenu("En cours de traitement")}
                    >
                      En Cours de traitement
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ gap: 1, fontSize: "14px" }}
                      onClick={() => handleClickMenu("NOUVEAU")}
                    >
                      Nouveau
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ gap: 1, fontSize: "14px" }}
                      onClick={() => handleClickMenu("Terminé")}
                    >
                      Terminé
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ gap: 1, fontSize: "14px" }}
                      onClick={() => handleClickMenu("Refusé")}
                    >
                      Refusé
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ gap: 1, fontSize: "14px" }}
                      onClick={() => handleClickMenu("Annulé")}
                    >
                      Annulé
                    </MenuItem>
                  </StyledMenu>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((offer) => (
                <TableRow
                  key={offer.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box
                      onClick={() => goToDetails(offer)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { color: "#FF7900" },
                      }}
                    >
                      {offer.nom} {offer.prenom}
                    </Box>
                  </TableCell>
                  <TableCell>{offer.sousCategorie}</TableCell>
                  <TableCell>{offer.telephone}</TableCell>

                  {role === "ADMIN" ? (
                    <TableCell>
                      {offer.adminNom} {offer.adminPrenom}
                    </TableCell>
                  ) : null}
                  <TableCell>{offer.adresse}</TableCell>
                  <TableCell>
                    {formatDate(offer.dateDemarrageSouhaiter)}
                  </TableCell>
                  <TableCell align="center">
                    {displayStatus(offer.statutGeneral)}
                  </TableCell>

                  <TableCell>
                    {offer.statutGeneral === "NOUVEAU" ? (
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            boxShadow: "none",
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: "#FF7900",
                              boxShadow: "none",
                            },
                          }}
                          onClick={() => validateOffer(offer.id, "valider")}
                        >
                          valider
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            boxShadow: "none",
                            textTransform: "capitalize",
                            backgroundColor: (theme) =>
                              theme.palette.error.dark,
                            "&:hover": {
                              backgroundColor: "#FF7900",
                              boxShadow: "none",
                            },
                          }}
                          onClick={() => validateOffer(offer.id, "refuser")}
                        >
                          Refuser
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          boxShadow: "none",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#FF7900",
                            boxShadow: "none",
                          },
                        }}
                        onClick={() => goToDetails(offer)}
                      >
                        Ouvrir
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <PaginationComponent
        page={page}
        totalPage={totalPages}
        onPreviousPage={() => {
          handlePreviousPage();
        }}
        onNextPage={() => {
          handleNextPage();
        }}
      />
      <Backdrop
        sx={{ color: "#00833D", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default OffresEnCommun;
