import CustomToast from '../../helpers/customToast';
import Api from '../../services/http/api';
import User from '../models/user';

class AuthCtrl {
  static login(loginDto, successCallBack, errorCallBack) {
    Api.call(
      loginDto,
      (response) => {
        
        const content = response.content;
        const user = new User(
          content.adresse,
          content.bedou,
          content.caution,
          content.email,
          content.expiresIn,
          content.idBoss,
          content.idGrouilleur,
          content.idUtilisateur,
          content.matricule,
          content.nom,
          content.prenom,
          content.telephone
        );
        sessionStorage.setItem('token', content.token);
        sessionStorage.setItem('user', JSON.stringify(user));
        successCallBack(response);
      },
      () => {
        errorCallBack();
      }
    );
  }
}

export default AuthCtrl;
