import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableRow,
  IconButton,
  Stack,
  Box,
  Button,
  Typography,
  Badge,
  Link,
  MenuItem,
  CircularProgress,
  Chip,
} from "@mui/material";
import {
 
  StyledMenu,
} from "../../../../assets/styles/Widgets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Icon } from "@iconify/react";
import userDataSlice, {
  reducerUserData,
} from "../../../../reducers/sessionReducer";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Offer from "../../models/offer";
import OfferCtrl from "../../controllers/offer_controller";
import GetOffersDto from "../../dto/get_offers_dto";
import PaginationComponent from "../../../../components/paginationComponent";

const BaraTable = (props) => {
  const searchResult = null;
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const [anchorEls, setAnchorEls] = React.useState({});
  const open = Boolean(anchorEls);
  const openStatus = Boolean(anchorElStatus);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("EN_ATTENTE");
  console.log(page);

  // Update handleClick to set anchor element for specific row
  const handleClick = (event, rowId) => {
    setAnchorEls({ ...anchorEls, [rowId]: event.currentTarget });
  };

  // Update handleClose to clear anchor element for specific row
  const handleClose = (rowId) => {
    setAnchorEls({ ...anchorEls, [rowId]: null });
  };
  const handleChangeStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  const handleClickMenu = async (todo) => {
    let newStatus;
    switch (todo) {
      case "En cour de traitement":
        newStatus = "EN_ATTENTE";
        break;
      case "Terminé":
        newStatus = "TERMINER";
        break;
      case "Refusé":
        newStatus = "REFUSER";
        break;
      case "Annulé":
        newStatus = "ANNULER";
        break;
      default:
        newStatus = status;
    }

    setStatus(newStatus);
    await fetchData(page, newStatus);
    handleCloseStatus();
  };

  const handleNextPage = async () => {
    if (totalPages === 1) return;
    if (totalPages === page + 1) return;
    setPage(page + 1);
    setLoading(true);

    await fetchData(page, status);
  };

  const handlePreviousPage = async () => {
    if (page < 1) return;
    setPage(page - 1);
    setLoading(true);
    await fetchData(page, status);
  };

  const fetchData = async (page, status) => {
    setData(null);
    setLoading(true);
    try {
      let responseData = null;
      let totalPages = 0;
      OfferCtrl.getOffres(
        new GetOffersDto(page, status),
        (res) => {
          console.log(res.content);
          responseData = res.content.content;
          totalPages = res.content.totalPages;
          setData(responseData);
          setTotalPages(totalPages);
        },
        () => {
          console.log("Error Message");
          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const displayStatus = (value) => {
    let accent;
    let label;
    switch (value) {
      case "EN_ATTENTE":
        accent = "warning";
        label = "En cours de traitement";
        break;
      case "EN_COURS":
        accent = "info";
        label = "En Cours";
        break;
      case "TERMINER":
        accent = "success";
        label = "Terminer";
        break;
      case "REFUSER":
        accent = "error";
        label = "Refuser";
        break;
      case "ANNULER":
        accent = "error";
        label = "Annuler";
        break;
      default:
        accent = "success";
        label = "Terminer";
    }

    // const badge = <Badge color={accent} badgeContent=""></Badge>;
    const chip = <Chip color={accent} label={label} />;
    return chip;
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}/${(
      dateObj.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${dateObj.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    try {
      fetchData(page, status);
    } catch (error) {
      console.log(error);
    }
  }, [page, status]);

  if (loading || data === null) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  const goToDetails = (offer) => {
    let path = "/home/bara-details/";
    navigate(path, { state: { offer: offer } });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: window.screen.height - 430,
          boxShadow:
            "0px 1px 0px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom du boss</TableCell>
              <TableCell>Sous catégorie</TableCell>
              <TableCell>Telephone</TableCell>
              <TableCell>
                <Icon icon="heroicons:map-pin" width="20px" height="14px" />{" "}
                Adresse bara
              </TableCell>
              <TableCell>Date besoin</TableCell>
              <TableCell>
                <Button
                  id="status-button"
                  aria-controls={openStatus ? "status-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openStatus ? "true" : undefined}
                  variant="outlined"
                  disableRipple
                  elevation={2}
                  onClick={handleChangeStatus}
                  endIcon={
                    <KeyboardArrowDownIcon
                      sx={{ color: "rgba(155, 166, 156, 1)" }}
                    />
                  }
                  sx={{ borderColor: "#F8F8F8", textTransform: "none" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: "#000000",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <Box>Status</Box>
                    </Typography>
                    <Stack direction="row" spacing={1}></Stack>
                  </Box>
                </Button>
                <StyledMenu
                  id="status-menu"
                  MenuListProps={{
                    "aria-labelledby": "status-button",
                  }}
                  anchorEl={anchorElStatus}
                  open={openStatus}
                  onClose={handleCloseStatus}
                >
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleClickMenu("En cour de traitement")}
                  >
                    En Cour de traitement
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleClickMenu("Terminé")}
                  >
                    Terminé
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleClickMenu("Refusé")}
                  >
                    Refusé
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    sx={{ gap: 1, fontSize: "14px" }}
                    onClick={() => handleClickMenu("Annulé")}
                  >
                    Annulé
                  </MenuItem>
                </StyledMenu>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Table Rows */}
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell key={row.id}>
                  <Link
                    onClick={() => goToDetails(row)}
                    variant="body2"
                    fontSize="14px"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        fontWeight: "bold",
                        color: "orange",
                      },
                    }}
                  >
                    {row.nom} {row.prenom}
                  </Link>
                </TableCell>
                <TableCell key={row.sousCategorie}>
                  {row.sousCategorie}
                </TableCell>
                <TableCell key={row.telephone}>{row.telephone}</TableCell>
                <TableCell key={row.adresse}>{row.adresse}</TableCell>
                <TableCell key={row.dateCreation}>
                  {formatDate(row.dateCreation)}
                </TableCell>

                <TableCell key={row.statutGeneral} align="center">
                  {displayStatus(row.statutGeneral)}
                </TableCell>
                <TableCell>
                  {status === "EN_COURS" ? (
                    <>
                      <IconButton
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(event) => handleClick(event, row.id)}
                      >
                        <Icon icon="pepicons-pencil:dots-y-circle" />
                      </IconButton>
                      <StyledMenu
                        id="status-menu"
                        MenuListProps={{
                          "aria-labelledby": "status-button",
                        }}
                        anchorEl={anchorEls[row.id]}
                        open={Boolean(anchorEls[row.id])}
                        onClose={() => handleClose(row.id)}
                      >
                        <MenuItem
                          disableRipple
                          sx={{ gap: 1, fontSize: "14px" }}
                          onClick={() => {
                            goToDetails(row);
                            handleClose(row.id);
                          }}
                        >
                          Detail du bara
                        </MenuItem>
                        <MenuItem
                          disableRipple
                          sx={{ gap: 1, fontSize: "14px" }}
                          onClick={() => {
                            handleClose(row.id);
                          }}
                        >
                          Changer le statut
                        </MenuItem>
                      </StyledMenu>
                    </>
                  ) : (
                    <Button
                      style={{
                        boxShadow: "none",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={() => goToDetails(row)}
                    >
                      Ouvrir
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        page={page}
        totalPage={totalPages}
        onPreviousPage={() => {
          handlePreviousPage();
        }}
        onNextPage={() => {
          handleNextPage();
        }}
      />
    </>
  );
};

export default BaraTable;
