import React from 'react'
import {
    Box,
    Typography,
    Toolbar,
} from "@mui/material";

const Header = () => {
    return (
        <>
            <Toolbar />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 3, pb: 3 }}>
                <Box sx={{ width: "30%" }}>
                    <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "left", m: 0 }}>Paramètres</Typography>
                </Box>
            </Box>
        </>
    )
}

export default Header