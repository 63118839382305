import Api from '../../../services/http/api';
import DeleteConversationDto from '../dto/delete_conversation';
import ConversationsListDto from '../dto/get_conversations_list';
import MessagesListDto from '../dto/get_messages_list';
import SendMessageDto from '../dto/send_message';
import Conversation from '../models/conversation';
import Message from '../models/message';

class ConversationsCtrl {
  static getConversationsList(successCallBack, errorCallBack) {
    Api.call(
      new ConversationsListDto(),
      (response) => {
        const content = response.content;
        const conversations = [];
        for (let i = 0; i < content.length; i++) {
          conversations.push(
            new Conversation(
              content[i].idGrouilleur,
              new Date(content[i].dateDernierMessage)
                .toLocaleString()
                .split(' ')[0],
              content[i].dernierMessage,
              content[i].destinataire,
              content[i].emetteur,
              content[i].nonLu,
              Api.imageBaseUrl + content[i].photoProfile
            )
          );
        }
        successCallBack(conversations);
      },
      () => {
        errorCallBack();
      }
    );
  }

  static getMessagesList(idGrouilleur, successCallBack, errorCallBack) {
    Api.call(
      new MessagesListDto(idGrouilleur),
      (response) => {
        const content = response.content;
        const messages = [];
        for (let i = 0; i < content.length; i++) {
          messages.push(
            new Message(
              new Date(content[i].dateCreation).toLocaleString().split(' ')[0],
              content[i].message,
              content[i].discussion.emetteur
            )
          );
        }
        successCallBack(messages);
      },
      () => {
        errorCallBack();
      }
    );
  }

  static sendMessage(idGrouilleur, message, successCallBack, errorCallBack) {
    Api.call(
      new SendMessageDto(idGrouilleur, message),
      (response) => {
        const content = response.content;
        const messages = new Message(
          new Date(content.dateCreation).toLocaleString().split(' ')[0],
          content.message,
          content.discussion.emetteur
        );
        successCallBack(messages);
      },
      () => {
        errorCallBack();
      }
    );
  }

  static deleteConversation(idConversations, successCallBack, errorCallBack) {
    Api.call(
      new DeleteConversationDto(idConversations),
      (response) => {
        successCallBack();
      },
      () => {
        errorCallBack();
      }
    );
  }
}

export default ConversationsCtrl;
