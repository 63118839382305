import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Alert, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dropzone from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import axios from 'axios';
import CustomToast from '../../../../../helpers/customToast';
import Api from '../../../../../services/http/api';

const AddSubcategoryModal = ({ open, onClose, selectedCategory, handleAddSubcategory }) => {
    const [newSubcategoryLabel, setNewSubcategoryLabel] = useState('');
    const [newSubcategoryPhoto, setNewSubcategoryPhoto] = useState(null);
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const name = selectedCategory ? selectedCategory.libelle : '';
    useEffect(() => {
        setIsFormValid(selectedCategory && newSubcategoryLabel && newSubcategoryPhoto);
    }, [selectedCategory, newSubcategoryLabel, newSubcategoryPhoto]);

    const handleSubmit = () => {
        if (isFormValid) {
            const newSubcategory = {
                libelleSuperCategorie: name,
                libelle: newSubcategoryLabel,
                referenceFichier: newSubcategoryPhoto
            };
            handleAddSubcategory(newSubcategory);
            setNewSubcategoryLabel('');
            setNewSubcategoryPhoto(null);
            onClose();
        } else {
            CustomToast.showError("Tous les champs sont requis.");
        }
    };

    const handleDrop = async (acceptedFiles) => {
        const validExtensions = ['.png', '.jpg', '.jpeg'];
        const file = acceptedFiles[0];
        const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

        if (!validExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
            setError('Extensions acceptées (.PNG, .JPG ou .JPEG)');
            return;
        }

        const token = sessionStorage.getItem("token");

        try {
            const toastId = CustomToast.showLoading("fichier en cours Importation.");
            const formData = new FormData();
            formData.append('file', file);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            };

            const response = await axios.post(
              `${Api.baseUrl}/api/v3/admin-all/file`,
              formData,
              config
            );
            setNewSubcategoryPhoto(response.data.content.path);
            CustomToast.hideLoading(toastId);
            CustomToast.showSuccess("Image importeé avec succès");
        } catch (error) {
            CustomToast.showError("Impossible d'importer cette image !");
            console.error('Erreur lors du téléchargement du fichier :', error);
        } 
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{ width: 500, p: 3, mx: 'auto', mt: '10%', backgroundColor: 'background.paper', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6" component="h2" sx={{ flexGrow: 1, fontWeight: 'bold', textAlign: 'center' }}>
                            Nouvelle sous-catégorie
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        label="Catégorie"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        value={name}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        label="Libellé de la sous-catégorie"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        value={newSubcategoryLabel}
                        onChange={(e) => setNewSubcategoryLabel(e.target.value)}
                    />
                    <Dropzone onDrop={handleDrop} accept=".png, .jpg, .jpeg">
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}
                                    style={{
                                        border: '1px solid #CECECE',
                                        height: 150,
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        backgroundColor: '#F8F8F8'
                                    }}>
                                    <input {...getInputProps()} />
                                    <p>
                                        <CloudUploadOutlinedIcon sx={{ fontSize: '80px', color: '#C9C9C9' }} />
                                        <div>
                                            {newSubcategoryPhoto ? (
                                                <div>
                                                    Image sélectionnée avec succès !
                                                </div>
                                            ) : (
                                                <div>
                                                    Sélectionnez ou déposez une image
                                                </div>
                                            )}
                                        </div>
                                    </p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                    <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }} disabled={!isFormValid}>
                        Ajouter
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default AddSubcategoryModal;
