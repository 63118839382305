import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import Header from "./views/Header";
import Sidebar from "./views/Sidebar";
import CategoryList from "./views/categories/CategoryList";
import SubcategoryList from "./views/categories/SubcategoryList";
import axios from "axios";
import { getCookie } from "cookies-next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GestionDeMotDePasse from "./views/GestionDeMotDePasse";
import GestionDesUtilisateur from "./views/GestionDesUtilisateur";
import CategoryAddModal from "./views/categories/components/CategoryAddModal";
import Api from "../../services/http/api";

const Parametres = () => {
  const userRole = getCookie("role");
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sousPage, setSousPage] = useState(1);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [sousFilter, setSousFilter] = useState("");
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [activeSousPage, setActiveSousPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    userRole === "ADMIN" || userRole === "MANAGER"
      ? fetchCategories()
      : setActiveIndex(2);
  }, []);

  const fetchCategories = async () => {
    try {
      setOpenBackdrop(false);
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await Promise.race([
        axios.get(
          `${Api.baseUrl}/api/v3/admin-all/super-categorie?pageIndex=0&pageSize=9999`,
          config
        ),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error("Timeout")), 5000)
        ),
      ]);

      if (response?.data?.content?.content) {
        const categories = response.data.content.content;

        const categoriesWithSubCategories = await Promise.all(
          categories.map(async (cat) => {
            const categoryData = {
              libelle: cat.libelle ? String(cat.libelle) : "",
              actif: cat.actif,
              disponible: cat.disponible,
              id: cat.id,
              referenceFichier: cat.referenceFichier,
              sousCategories: [],
            };

            try {
              const result = await axios.get(
                `${Api.baseUrl}/api/v3/admin-all/super-categorie/${cat.id}/categories`,
                config
              );
              categoryData.sousCategories = result.data.content.map(
                (subCategory) => ({
                  dateCreation: subCategory.dateCreation,
                  id: subCategory.id,
                  libelle: subCategory.libelle
                    ? String(subCategory.libelle)
                    : "",
                  referenceFichier: subCategory.referenceFichier,
                })
              );
            } catch (error) {
              console.error(
                "Erreur lors de la récupération des sous-catégories pour la catégorie",
                cat.id,
                error
              );
            }

            return categoryData;
          })
        );

        setCategories(categoriesWithSubCategories);
      } else {
        console.error("Réponse de l'API incomplète ou vide");
      }

      setOpenBackdrop(false);
    } catch (error) {
      console.error("Erreur lors du chargement des catégories:", error);
      setCategories([]);
      setOpenBackdrop(false);
    }
  };

  const handleListItemClick = (index) => {
    setActiveIndex(index);
    setSelectedCategory(null);
    setShowSubcategories(false);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSousPage(1);
    setShowSubcategories(true);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setSousPage(1);
  };

  const handleSousFilterChange = (event) => {
    setSousFilter(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSousPageChange = (newPage) => {
    setSousPage(newPage);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    setActivePage((prevActivePage) => Math.max(prevActivePage - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    setActivePage((prevActivePage) => prevActivePage + 1);
  };

  const handlePreviousSousPage = () => {
    setSousPage((prevSousPage) => Math.max(prevSousPage - 1, 1));
    setActiveSousPage((prevActiveSousPage) =>
      Math.max(prevActiveSousPage - 1, 1)
    );
  };

  const handleNextSousPage = () => {
    setSousPage((prevSousPage) => prevSousPage + 1);
    setActiveSousPage((prevActiveSousPage) => prevActiveSousPage + 1);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddCategory = async (newCategory) => {
    console.log(newCategory);
  };

  const categoriesPerPage = 2;
  const totalPages = Math.ceil(categories.length / categoriesPerPage);
  const totalSousPages = selectedCategory
    ? Math.ceil(selectedCategory.sousCategories.length / categoriesPerPage)
    : 1;

  const renderPageButtons = (totalPages, currentPage, handlePageChange) => {
    const buttons = [];
    const maxButtons = 3;
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, totalPages);
    if (endPage - startPage + 1 < maxButtons) {
      startPage = Math.max(endPage - maxButtons + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          variant={currentPage === i ? "contained" : "text"}
          sx={{ borderRadius: 8 }}
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <div>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Header />
        <ToastContainer />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flex: "0 0 20%",
              marginBottom: "5%",
              "@media (max-width: 100%)": {
                flex: "0 0 100%",
                marginBottom: "2.5%",
              },
            }}
          >
            <Box sx={{ borderRadius: 2, border: "none" }}>
              <Sidebar
                activeIndex={activeIndex}
                handleListItemClick={handleListItemClick}
              />
            </Box>
          </Box>

          {activeIndex === 0 && <GestionDesUtilisateur />}

          {activeIndex === 1 && (
            <Box
              sx={{
                borderRadius: 2,
                flex: "0 0 75%",
                "@media (max-width: 600px)": { flex: "0 0 100%" },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <CategoryList
                  categories={categories}
                  filter={filter}
                  handleFilterChange={handleFilterChange}
                  page={page}
                  categoriesPerPage={categoriesPerPage}
                  handleCategoryClick={handleCategoryClick}
                  handlePreviousPage={handlePreviousPage}
                  handleNextPage={handleNextPage}
                  renderPageButtons={renderPageButtons}
                  totalPages={Math.ceil(categories.length / categoriesPerPage)}
                  handlePageChange={handlePageChange}
                  fetchCategories={fetchCategories}
                />

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mx: 2, borderWidth: "3px", borderColor: "black" }}
                />

                <SubcategoryList
                  selectedCategory={selectedCategory}
                  sousFilter={sousFilter}
                  handleSousFilterChange={handleSousFilterChange}
                  sousPage={sousPage}
                  categoriesPerPage={categoriesPerPage}
                  handlePreviousSousPage={handlePreviousSousPage}
                  handleNextSousPage={handleNextSousPage}
                  renderPageButtons={renderPageButtons}
                  totalSousPages={totalSousPages}
                  handleSousPageChange={handleSousPageChange}
                  fetchCategories={fetchCategories}
                />
              </Box>
            </Box>
          )}

          {activeIndex === 2 && <GestionDeMotDePasse />}
        </Box>
      </Box>

      <CategoryAddModal
        open={openModal}
        onClose={handleCloseModal}
        handleAddCategory={handleAddCategory}
      />
    </div>
  );
};

export default Parametres;
