import React, { useEffect, useState } from 'react';
import {
    Button, Modal, TextField, Autocomplete, Box, Typography, Stack, IconButton, Backdrop, CircularProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createUtilisateurDto from '../dto/createUtilisateurDto';
import Api from '../../../services/http/api';
import CustomToast from '../../../helpers/customToast';
import UtilisateursDto from '../dto/UtilisateursDto';

const schema = yup.object().shape({
    nom: yup.string().required('Le Nom est obligatoire').min(6, "Renseignez au moins 06 caractères."),
    prenom: yup.string().required('Le Prénom est obligatoire').min(5, "Renseignez au moins 06 caractères."),
    email: yup.string().email('Désolé cette adresse email est invalide.').required('Cette adresse email est obligatoire'),
    telephone: yup.string().required('Le numéro de téléphone est obligatoire').min(9, "Renseignez au moins 06 caractères."),
    role: yup.string().required('Sélectionnez un rôle avant de continuer')
});

const AjouterUtilisateur = ({ onUserAdded }) => {
    const [openModal, setOpenModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const [size, setSize] = useState(150);

    const fetchRoles = async () => {
        try {
            const dto = new UtilisateursDto("list", 0, size);
            console.log(dto);
            Api.call(
                dto,
                async (res) => {
                    setSize(res.content.totalElements);
                    console.log(res);
                    const users = res.content.content;
                    const unique = [...new Set(users.map(user => user.roleUtilisateur))];
                    setRoles(unique);
                },
                (err) => {}
            );
        } catch (error) {
            console.error('Erreur lors du chargement des rôles:', error);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const formik = useFormik({
        initialValues: {
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            role: ''
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            handleCloseModal();
            const toastId = CustomToast.showLoading("Enregistrement en cours.");
            const dto = new createUtilisateurDto(values);
            console.log(dto);
        
            Api.call(
                dto,
                async (res) => {
                    console.log(res);
                    CustomToast.hideLoading(toastId);
                    CustomToast.showSuccess(res.message);
                    await onUserAdded();
                },
                (err) => {
                    CustomToast.hideLoading(toastId);
                }
            );
        }
    });

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{
                    boxShadow: 'none',
                    textTransform: 'none',
                    marginLeft: 'auto',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                }}
            >
                Créer un utilisateur
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6" component="h2" sx={{ flexGrow: 1, fontWeight: 'bold', textAlign: 'center' }}>
                            Créer un nouvel utilisateur
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>
                            <TextField
                                label="Nom"
                                variant="outlined"
                                name="nom"
                                value={formik.values.nom}
                                onChange={formik.handleChange}
                                error={formik.touched.nom && Boolean(formik.errors.nom)}
                                helperText={formik.touched.nom && formik.errors.nom}
                            />
                            <TextField
                                label="Prénom"
                                variant="outlined"
                                name="prenom"
                                value={formik.values.prenom}
                                onChange={formik.handleChange}
                                error={formik.touched.prenom && Boolean(formik.errors.prenom)}
                                helperText={formik.touched.prenom && formik.errors.prenom}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                label="Téléphone"
                                variant="outlined"
                                name="telephone"
                                value={formik.values.telephone}
                                onChange={formik.handleChange}
                                error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                                helperText={formik.touched.telephone && formik.errors.telephone}
                            />
                            <Autocomplete
                                options={roles}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Rôle"
                                        variant="outlined"
                                        error={formik.touched.role && Boolean(formik.errors.role)}
                                        helperText={formik.touched.role && formik.errors.role}
                                    />
                                )}
                                value={formik.values.role}
                                onChange={(event, value) => formik.setFieldValue('role', value)}
                            />
                            <Button type="submit" variant="contained" color="primary">
                                Enregistrer
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default AjouterUtilisateur;
