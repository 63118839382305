// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  border-radius: 8px 0 8px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

button {
  border: none;
  color: #737373;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/grouilleurs/views/ImageZoomInOut.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,sBAAsB;EACtB,wBAAwB;EACxB,gBAAgB;EAChB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".btn-container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  background-color: #fff;\n  border-radius: 8px 0 8px;\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 100;\n}\n\nbutton {\n  border: none;\n  color: #737373;\n  background-color: #fff;\n  padding: 10px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
