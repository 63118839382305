import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const PaginationComponent = (props) => {
  const page = props.page;
  const totalPage = props.totalPage;
  const onPreviousPage = props.onPreviousPage;
  const onNextPage = props.onNextPage;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10,
      }}
    >
      <span>Page</span>
      <span style={{ marginInline: 10 }}>{page + 1}</span>
      <span>sur</span>
      <span style={{ marginInline: 10 }}>{totalPage}</span>
      <NavigateBeforeRoundedIcon
        sx={{
          cursor: 'pointer',
          borderRadius: 1,
          '&:hover': {
            backgroundColor: 'rgba(0, 131, 61, 0.8)',
            color: '#FFFFFF',
          },
        }}
        onClick={onPreviousPage}
      />
      <div style={{ width: 10 }}></div>
      <NavigateNextRoundedIcon
        sx={{
          cursor: 'pointer',
          borderRadius: 1,
          '&:hover': {
            backgroundColor: 'rgba(0, 131, 61, 0.8)',
            color: '#FFFFFF',
          },
        }}
        onClick={onNextPage}
      />
    </div>
  );
};

export default PaginationComponent;
