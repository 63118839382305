import React, { useState, useRef, useEffect, useCallback } from "react";
import "./ImageZoomInOut.css";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";
import { AppButton } from "../../../assets/styles/Widgets";

function ImageZoomInOut({ imageUrl, data, badgeAction, setOpen }) {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageRef = useRef(null);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleZoomIn = () => {
    setScale((scale) => scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((scale) => scale - 0.1);
  };

  useEffect(() => {
    const image = imageRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };
      setPosition((position) => ({
        x: position.x + deltaX,
        y: position.y + deltaY,
      }));
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    image?.addEventListener("mousedown", handleMouseDown);
    image?.addEventListener("mousemove", handleMouseMove);
    image?.addEventListener("mouseup", handleMouseUp);

    return () => {
      image?.removeEventListener("mousedown", handleMouseDown);
      image?.removeEventListener("mousemove", handleMouseMove);
      image?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [imageRef, scale, currentImageIndex]);

  // Reorder images to display imageUrl first
  const images = [...data];
  console.log(images);
  const imageUrlIndex = images.findIndex((image) => image.src === imageUrl);
  if (imageUrlIndex > -1) {
    const [imageUrlObj] = images.splice(imageUrlIndex, 1);
    images.unshift(imageUrlObj);
  }

  const handleImageRef = useCallback((node) => {
    if (node !== null) {
      imageRef.current = node;
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#202124",
        borderRadius: "10px",
        position: "relative",
        overflow: "hidden",
        height: "80vh",
      }}
    >
      <div className="btn-container">
        <button onClick={handleZoomIn}>
          <Icon
            icon="bi:zoom-in"
            width="48"
            height="48"
            style={{ color: "#787878" }}
          />
        </button>
        <button onClick={handleZoomOut}>
          <Icon
            icon="bi:zoom-out"
            width="48"
            height="48"
            style={{ color: "#787878" }}
          />
        </button>
        <button onClick={() => handleDownload(imageUrl)}>
          <Icon
            icon="mdi:download"
            width="48"
            height="48"
            style={{ color: "#787878" }}
          />
        </button>
      </div>
      <Box>
        <Box
          sx={{
            height: "85vh",
            placeItems: "center",
            width: "100%",
          }}
        >
          <Carousel
            interval={null}
            // data-bs-theme="dark"
            style={{ maxWidth: "100%", height: "95%", overflow: "hidden" }}
            onSlide={(e, direction) => setCurrentImageIndex(e)}
          >
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                    overflow: "hidden",
                    boxSizing: "border-box",
                    bgcolor: "#202124",
                  }}
                >
                  <img
                    ref={index === currentImageIndex ? handleImageRef : null}
                    src={image.src}
                    alt=""
                    style={{
                      width: "auto",
                      height: "100%",
                      transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                      cursor: "move",
                    }}
                    draggable={false}
                  />
                </Box>
                {image.statut === "ATTENTE" || image.statut === "NON_VALIDE" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: 1,
                      py: 2,
                      bgcolor: "#202124",
                    }}
                  >
                    <AppButton
                      sx={{ background: "#00833D" }}
                      onClick={() => {
                        badgeAction("valider", image.id);
                        setOpen(false);
                      }}
                    >
                      valider
                    </AppButton>
                    <AppButton
                      sx={{ background: "#FF7900" }}
                      onClick={() => {
                        badgeAction("invalider", image.id);
                        setOpen(false);
                      }}
                    >
                      rejeter
                    </AppButton>
                  </Box>
                ) : null}
              </Carousel.Item>
            ))}
          </Carousel>
          <Box></Box>
        </Box>
      </Box>
    </div>
  );
}

export default ImageZoomInOut;
