import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PaginationComponent from "../../../components/paginationComponent";
// import Transaction from "../models/transaction";
import {
  StyledMenu,
  formatDate,
  AppButton,
} from "../../../assets/styles/Widgets";
import { Box, MenuItem, CircularProgress, Toolbar } from "@mui/material";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import "../styles/transactions.css";
import TransactionCtrl from "../controllers/transaction_controller";
import TransactionDto from "../dto/transactionDto";
// import DetailCtrl from "../controllers/detail_controller";
// import DetailDto from "../dto/detailDto";
import CustomToast from "../../../helpers/customToast";
import Api from "../../../services/http/api";

const Transactions = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [params, setParams] = useState("");
  const open = Boolean(anchorEl);
  const [anchorElExport, setAnchorElExport] = React.useState(null);
  const openExport = Boolean(anchorElExport);

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const handleClickExport = (event) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToDetails = async (transactionId) => {
    let details;
    setLoading(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/transaction/admin-all/${transactionId}`, // Use rowId here
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      details = response.data;
      console.log("details", details);

      routeChange({
        details: details,
      });
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  const routeChange = (data) => {
    let path = `/home/datails-transaction/`;
    navigate(path, { state: { data: data } });
  };

  const handleNextPage = async () => {
    if (totalPages === 1) return;
    if (totalPages === page + 1) return;
    setPage(page + 1);
    setLoading(true);
    await fetchData(page);
  };

  const handlePreviousPage = async () => {
    if (page < 1) return;
    setPage(page - 1);
    setLoading(true);
    await fetchData(page);
  };

  const fetchData = async (page) => {
    setData(null);
    setLoading(true);
    try {
      let responseData = null;
      let totalPages = 0;

      TransactionCtrl.transactionData(
        new TransactionDto(page),
        (res) => {
          console.log(res.content);
          responseData = res.content.data;
          totalPages = res.content.totalPages;
          setData(responseData);
          setTotalPages(totalPages);
        },
        () => {
          console.log("Error Message");
          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const exporterList = async (period) => {
    setOpenBackdrop(true);
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/transaction/admin-all/export/${period}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      const worksheet = XLSX.utils.json_to_sheet(response.data.content);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Liste des Retraits");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "Liste des Retraits.xlsx");
      setAnchorElExport(false);
      console.log(response);
    } catch (error) {
      console.log(error);
      setOpenBackdrop(false);
    } finally {
      setOpenBackdrop(false);
    }
  };

  const search = async (page) => {
    if (params.trim().length === 0) {
      CustomToast.showError(
        "Veuillez saisir un nom ou un numéro de téléphone valide"
      );
      return;
    }
    setLoading(true);
    let responseData = null;
    let totalPages = 0;

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/transaction/retrait/search?pageIndex=${page}&pageSize=50&querySearch=${params}&retrait=false`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response.data.content);
      responseData = response.data.content.content;
      totalPages = response.data.content.totalPages;
      setData(responseData);
      setTotalPages(totalPages);

      setLoading(false);
    } catch (error) {
      CustomToast.showError("la connexion a échoué");
      setLoading(false);

      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
    >
      <Toolbar />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          pt: 3,
          pb: 5,
          gap: 2,
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "40%" } }}>
          <Box
            sx={{
              textAlign: { xs: "center", md: "left" },
              fontWeight: "700",
              fontSize: "22px",
              m: 0,
            }}
          >
            Liste des transactions
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            display: "flex",
            flexDirection: "row",
            gap: 1,
            justifyContent: { xs: "space-between", md: "flex-end" },
            alignItems: "center",
          }}
        >
          <Box>
            <AppButton
              disableElevation
              id="export-button"
              aria-controls={openExport ? "export-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openExport ? "true" : undefined}
              variant="contained"
              onClick={handleClickExport}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ textTransform: "none", width: "110px", px: 1 }}
            >
              Exporter
            </AppButton>
            <StyledMenu
              id="export-menu"
              anchorEl={anchorElExport}
              open={openExport}
              onClose={() => setAnchorElExport(null)}
              MenuListProps={{
                "aria-labelledby": "export-menu",
              }}
            >
              <MenuItem onClick={() => exporterList("ANNEE_EN_COURS")}>
                Annee
              </MenuItem>
              <MenuItem onClick={() => exporterList("MOIS_EN_COURS")}>
                Mois
              </MenuItem>
              <MenuItem onClick={() => exporterList("JOUR_EN_COURS")}>
                Jour
              </MenuItem>
            </StyledMenu>
            <Box sx={{ display: "flex", flexDirection: "row", px: 2 }}></Box>
          </Box>
          <Box
            sx={{
              // width:"40%",
              height: 50,
              borderRadius: 25,
              backgroundColor: "rgba(181, 186, 181, 0.25)",
              display: "flex",
              p: 2,
            }}
          >
            <input
              className="searchInput"
              placeholder="Rechercher..."
              onChange={(event) => handleInputChange(event, setParams)}
            />
            <img
              alt="icon"
              src={searchIcon}
              style={{ cursor: "pointer" }}
              onClick={() => search(0)}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: window.screen.height - 430,
          boxShadow:
            "0px 1px 0px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      >
        {loading || data === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Destinataire</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Valeur</TableCell>
                <TableCell>
                  <div
                    onClick={handleClick}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Type
                    <KeyboardArrowDownIcon
                      sx={{ fontSize: 20, color: "rgb(155, 166, 156)" }}
                    />
                  </div>
                  <StyledMenu
                    id="status-menu"
                    MenuListProps={{
                      "aria-labelledby": "status-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }}>
                      Tout
                    </MenuItem>
                    <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }}>
                      Crédit
                    </MenuItem>
                    <MenuItem disableRipple sx={{ gap: 1, fontSize: "14px" }}>
                      Débit
                    </MenuItem>
                  </StyledMenu>
                </TableCell>
                <TableCell>Status</TableCell>

                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box
                      onClick={() => goToDetails(row.id)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { color: "#FF7900" },
                      }}
                    >
                      {row.destinataire.nom} {row.destinataire.prenom}
                    </Box>
                  </TableCell>
                  <TableCell>{formatDate(row.dateCreation)}</TableCell>
                  <TableCell>{row.valeur}</TableCell>
                  <TableCell>{row.typeOperation}</TableCell>
                  <TableCell>{row.statutTransaction}</TableCell>

                  <TableCell component="th" scope="row">
                    <Box
                      onClick={goToDetails}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { color: "#FF7900" },
                      }}
                    >
                      {row.description}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <PaginationComponent
        page={page}
        totalPage={totalPages}
        onPreviousPage={() => {
          handlePreviousPage();
        }}
        onNextPage={() => {
          handleNextPage();
        }}
      />
    </Box>
  );
};

export default Transactions;
