import CustomToast from '../../../helpers/customToast';
import Api from '../../../services/http/api';
import OffresEnCommunDto from '../dto/offres_en_commun';
import PositionnemetsSurUneOffreDto from '../dto/positionnemets_sur_une_offre';
import PositionnerUnGrouilleurSurUneOffreDto from '../dto/positionner_un_grouilleur_sur_une_offre';
import PrendreUneOffreEnChargeDto from '../dto/prendre_une_offre';
import Offer from '../models/offer';
import OffreEnCommun from '../models/offreEnCommun';
import Positionnement from '../models/positionnement';

class OfferCtrl {
  static getOffres(grouilleursDto, successCallBack, errorCallBack) {
    Api.call(
      grouilleursDto,
      (response) => {
        successCallBack(response);
      },
      () => {
        errorCallBack();
      }
    );
  }
  static offresCommuns(grouilleursDto, successCallBack, errorCallBack) {
    Api.call(
      grouilleursDto,
      (response) => {
        successCallBack(response);
      },
      () => {
        errorCallBack();
      }
    );
  }

  static prendreOffreEnCharge(offreId, successCallBack, errorCallBack) {
    Api.call(
      new PrendreUneOffreEnChargeDto(offreId),
      (response) => {
        CustomToast.showSuccess(response.message);
        successCallBack();
      },
      () => {
        errorCallBack();
      }
    );
  }

  static listerLesPositionnementsSurUneOffre(
    offreId,
    successCallBack,
    errorCallBack
  ) {
    Api.call(
      new PositionnemetsSurUneOffreDto(offreId),
      (response) => {
        CustomToast.showSuccess(response.message);
        const content = response.content;
        const positionnements = [];
        for (let i = 0; i < content.length; i++) {
          console.log(content[i]);
          positionnements.push(
            new Positionnement(
              content[i].id,
              content[i].grouilleur.idUser,
              content[i].grouilleur.idGrouilleur,
              content[i].grouilleur.prenom,
              content[i].grouilleur.nom,
              content[i].grouilleur.telephone,
              content[i].grouilleur.adresse,
              content[i].grouilleur.photoProfile,
              content[i].montantTravail
            )
          );
        }
        successCallBack(positionnements);
      },
      () => {
        errorCallBack();
      }
    );
  }

  static positionnerUnGrouilleurSurUneOffre(
    offreId,
    grouilleurId,
    montantTravail,
    successCallBack,
    errorCallBack
  ) {
    Api.call(
      new PositionnerUnGrouilleurSurUneOffreDto(
        offreId,
        grouilleurId,
        montantTravail
      ),
      (response) => {
        CustomToast.showSuccess(response.message);
        successCallBack();
      },
      () => {
        errorCallBack();
      }
    );
  }
}

export default OfferCtrl;
