class SendMessageDto {
  method = 'POST';
  constructor(idGrouilleur, message) {
    this.endpoint = '/api/v3/admin-all/message/' + idGrouilleur + '/send';
    this.data = {
      forceReply: true,
      message: message,
    };
  }
}

export default SendMessageDto;
