import React from 'react';
import { Box, Typography, IconButton, CardContent, CardMedia, Divider } from "@mui/material";
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Api from '../../../../../services/http/api';

const CategoryItem = ({ category, handleCategoryClick, openConfirmationModal, openEditModal }) => {
  return (
    <Box sx={{ width: "100%", mb: 1 }}>
      <Box sx={{ display: "flex", cursor: "pointer", p: 0 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              variant="h6"
              component="div"
              onClick={() => handleCategoryClick(category)}
            >
              {category.libelle}
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <IconButton
              onClick={() => openEditModal(category)}
              aria-label="edit-categorie"
              sx={{
                backgroundColor: "#E1FFE0",
                color: "#00833D",
                "&:hover": {
                  backgroundColor: "#E1FFE0",
                  color: "#00833D",
                },
              }}
            >
              <ModeOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete-categorie"
              size="small"
              onClick={() => openConfirmationModal(category)}
              sx={{
                backgroundColor: "#FFEEEE",
                mx: 2,
                color: "#EF2E2E",
                "&:hover": {
                  backgroundColor: "#FFEEEE",
                  color: "#EF2E2E",
                },
              }}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
        <CardMedia
          onClick={() => handleCategoryClick(category)}
          component="img"
          sx={{ width: 140, marginLeft: "auto" }}
          image={
            `${Api.baseUrl}/api/file?fileReference=` + category.referenceFichier
          }
          alt={`Image de ${category.nom}`}
        />
      </Box>
      <Divider
        sx={{ width: "95%", marginLeft: "auto", marginRight: "auto", mt: 2 }}
      />
    </Box>
  );
};

export default CategoryItem;
