// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range {
  background-color: rgba(181, 186, 181, 0.25);
  font-size: 14;
  border: none;
  flex-grow: 1;
  border-radius: 4px;
  padding-inline: 4px;
}

.date-range:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/transaction/styles/transactions.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".date-range {\n  background-color: rgba(181, 186, 181, 0.25);\n  font-size: 14;\n  border: none;\n  flex-grow: 1;\n  border-radius: 4px;\n  padding-inline: 4px;\n}\n\n.date-range:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
