import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';

class CustomToast {
  static showSuccess(message) {
    toast.success(message, {
      position: "top-right",
      duration: 5000,
      
    });
  }

  static showError(message) {
    toast.error(message, {
      position: "top-right",
      duration: 5000,
    });
  }

  static showWarnin(message) {
    toast(message, {
      position: "top-right",
      icon: '⚠️',
    });
  }

  static showLoading(message) {
    const toastId = toast.loading(message, {
      position: "top-right",
      duration: 0,
      icon: <Spinner animation="border" variant="success" />,
    });

    return toastId;
  }

  static hideLoading(toastId) {
    toast.dismiss(toastId);
  }
}

export default CustomToast;
