// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchInput {
  font-size: 14;
  border: none;
  background-color: transparent;
  margin-left: 10px;
  flex-grow: 1;
}
.searchInput:focus {
  outline: none;
}
.searchInput::placeholder {
  color: rgba(181, 186, 181, 1);
}

.MuiMenu-paper .MuiList-root {
  background-color: #e7ffe7;
}

.deleteBtn:hover {
  background-color: #ffe4e4;
}

.sendBtn:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/messages/styles/conversations.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,6BAA6B;EAC7B,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".searchInput {\n  font-size: 14;\n  border: none;\n  background-color: transparent;\n  margin-left: 10px;\n  flex-grow: 1;\n}\n.searchInput:focus {\n  outline: none;\n}\n.searchInput::placeholder {\n  color: rgba(181, 186, 181, 1);\n}\n\n.MuiMenu-paper .MuiList-root {\n  background-color: #e7ffe7;\n}\n\n.deleteBtn:hover {\n  background-color: #ffe4e4;\n}\n\n.sendBtn:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
