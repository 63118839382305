import Api from '../../../services/http/api';

class RetraitsCtrl {
  static retraitsData(retraitsDto, successCallBack, errorCallBack) {
    Api.call(
      retraitsDto,
      (response) => {
        successCallBack(response);
      },
      () => {
        errorCallBack();
      }
    );
  }
}

export default RetraitsCtrl;
