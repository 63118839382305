import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PaginationComponent from "../../components/paginationComponent";
import { AppButton, TextBox1, TextBox2 } from "../../assets/styles/Widgets";
import {
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Toolbar,
  Typography,
  Backdrop,
  Chip,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import searchIcon from "../../assets/icons/searchIcon.svg";
import "./styles/retraits.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { formatDate } from "../../assets/styles/Widgets";
import CustomToast from "../../helpers/customToast";
import RetraitsCtrl from "./controllers/retraits_controller";
import RetraitsDto from "./dto/retraitsDto";
import Api from "../../services/http/api";
import { Icon } from "@iconify/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Retraits = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [data, setData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openRefuser, setOpenRefuser] = useState(false);
  const [urlType, setUrlType] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [motif, setMotif] = useState("");
  const [params, setParams] = useState("");

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleClickOpen = (row) => {
    setMotif("");
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClickOpenRefuser = (row) => {
    setMotif("");
    setSelectedRow(row);
    setOpenRefuser(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRefuser = () => {
    setOpenRefuser(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openListTypes = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseListTypes = async (event) => {
    setAnchorEl(null);
    const action = event.target.innerText;
    if (action === "Tout") {
      setUrlType("");
    } else if (action === "En Attente") {
      setUrlType("&statusTransaction=en_attente");
    } else if (action === "Annuler") {
      setUrlType("&statusTransaction=annuler");
    }

    await fetchData(urlType, page);
  };

  const handleNextPage = async () => {
    if (totalPages === 1) return;
    if (totalPages === page + 1) return;
    setPage(page + 1);
    setLoading(true);

    await fetchData(urlType, page);
  };

  const handlePreviousPage = async () => {
    if (page < 1) return;
    setPage(page - 1);
    setLoading(true);
    await fetchData(urlType, page);
  };

  const fetchData = async (urlType, page) => {
    setData(null);
    setLoading(true);
    try {
      let responseData = null;
      let totalPages = 0;
      RetraitsCtrl.retraitsData(
        new RetraitsDto(page, urlType),
        (res) => {
          console.log(res.content);
          responseData = res.content.content;
          totalPages = res.content.totalPages;
          setData(responseData);
          setTotalPages(totalPages);
        },
        () => {
          console.log("Error Message");
          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const validateRetrait = async (numeroTransaction) => {

    setData(null);
    setLoading(true);
    const postData = {
      reference: motif,
    };
    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/transaction/retrait/validate/${numeroTransaction}`,
        method: "POST",
        data: postData,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      CustomToast.showSuccess(response.data.message);
    } catch (error) {
      console.log(error);
      CustomToast.showError("Opération Echoué");
    } finally {
      await fetchData(urlType, page);
      setLoading(false);
    }
  };


  const cancelRetrait = async (numeroTransaction) => {
    setData(null);
    setLoading(true);
    const postData = {
      motif: motif,
    };

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/transaction/retrait/cancel/${numeroTransaction}`,
        method: "POST",
        data: postData,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      CustomToast.showSuccess(response.data.message);
    } catch (error) {
      console.log(error);
      CustomToast.showError("Opération Echoué");
    } finally {
      await fetchData(urlType, page);
      setLoading(false);
    }
  };

  const search = async (page) => {
    if (params.trim().length === 0) {
      CustomToast.showError(
        "Veuillez saisir un nom ou un numéro de téléphone valide"
      );
      return;
    }
    setLoading(true);
    let responseData = null;
    let totalPages = 0;

    try {
      const response = await axios({
        url: `${Api.baseUrl}/api/v3/admin-all/transaction/retrait/search?pageIndex=${page}&pageSize=50&querySearch=${params}&retrait=true`,
        method: "GET",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response.data.content);
      responseData = response.data.content.content;
      totalPages = response.data.content.totalPages;
      setData(responseData);
      setTotalPages(totalPages);

      setLoading(false);
    } catch (error) {
      CustomToast.showError("la connexion a échoué");
      setLoading(false);

      console.log(error);
    } finally {
    }
  };

  const handleReload = async () => {
    // setKeyValue((keyValue) => keyValue + 1);
    setData(null);
    setPage(0);
    await fetchData(urlType, page);
  };

  useEffect(() => {
    fetchData(urlType, page);
  }, [page, urlType]);

  const displayStatus = (value) => {
    let accent;
    let label;
    switch (value) {
      case "EN_ATTENTE":
        accent = "info";
        label = "En Attente";
        break;
      case "EFFECTUER":
        accent = "success";
        label = "Effectuer";
        break;
      case "REFUSER":
        accent = "error";
        label = "Refuser";
        break;
      case "ANNULER":
        accent = "error";
        label = "Annuler";
        break;
      default:
        accent = "success";
        label = "Effectuer";
    }

    const chip = <Chip color={accent} label={label} />;
    return chip;
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            pt: 3,
            pb: 5,
            gap: 2,
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                fontWeight: "700",
                fontSize: "22px",
                m: 0,
              }}
            >
              Liste des demandes de retrait
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              display: "flex",
              flexDirection: "row",
              gap: 1,
              justifyContent: { xs: "space-between", md: "flex-end" },
              alignItems: "center",
            }}
          >
            <Box>
              <AppButton
                disableElevation
                variant="contained"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ textTransform: "none", width: "100px" }}
              >
                Statut
              </AppButton>
              <Box sx={{ display: "flex", flexDirection: "row", px: 2 }}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openListTypes}
                  onClose={handleCloseListTypes}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseListTypes}>Tout</MenuItem>
                  <MenuItem onClick={handleCloseListTypes}>En Attente</MenuItem>
                  <MenuItem onClick={handleCloseListTypes}>Annuler</MenuItem>
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                // width:"40%",
                height: 50,
                borderRadius: 25,
                backgroundColor: "rgba(181, 186, 181, 0.25)",
                display: "flex",
                p: 2,
              }}
            >
              <input
                className="searchInput"
                placeholder="Rechercher..."
                onChange={(event) => handleInputChange(event, setParams)}
              />
              <img
                alt="icon"
                src={searchIcon}
                style={{ cursor: "pointer" }}
                onClick={() => search(page)}
              />
            </Box>
          </Box>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "65vh",
            }}
          >
            {loading || data === null ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Demandeur</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>montant</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <>
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell key={row.emetteur.nom}>
                          {row.emetteur.nom} {row.emetteur.prenom}
                        </TableCell>
                        <TableCell key={row.dateCreation}>
                          {formatDate(row.dateCreation)}
                        </TableCell>
                        {/* <TableCell>{row.typeOperation}</TableCell> */}
                        <TableCell key={row.valeur}>{row.valeur}</TableCell>
                        <TableCell key={row.wallet} component="th" scope="row">
                          {row.wallet === null ? (
                            "Aucun"
                          ) : (
                            <Typography>
                              {" "}
                              {row.wallet.numero} Wallet :{" "}
                              {row.wallet.typeWallet}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell key={row.statutTransaction}>
                          {displayStatus(row.statutTransaction)}
                        </TableCell>
                        <TableCell>
                          {row.statutTransaction === "ANNULER" ||
                          row.statutTransaction === "EFFECTUER" ? null : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  boxShadow: "none",
                                  textTransform: "capitalize",
                                  "&:hover": {
                                    boxShadow: "none",
                                  },
                                }}
                                onClick={() => handleClickOpen(row)}
                              >
                                Valider
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  boxShadow: "none",
                                  textTransform: "capitalize",
                                  backgroundColor: "red",
                                  "&:hover": {
                                    boxShadow: "none",
                                  },
                                }}
                                onClick={() => handleClickOpenRefuser(row)}
                              >
                                Refuser
                              </Button>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <Box
            sx={{
              py: 2,
              px: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <IconButton>
              <Icon
                icon="oi:reload"
                style={{ color: "#787878" }}
                onClick={() => handleReload()}
              />
            </IconButton>
            <PaginationComponent
              page={page}
              totalPage={totalPages}
              onPreviousPage={() => {
                handlePreviousPage();
              }}
              onNextPage={() => {
                handleNextPage();
              }}
            />
          </Box>
        </Paper>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Validation du retrait"}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          {selectedRow && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pb: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <TextBox1>Date</TextBox1>
                <TextBox2>{formatDate(selectedRow.dateCreation)}</TextBox2>
                <TextBox1>Montant</TextBox1>
                <TextBox2>{selectedRow.valeur}</TextBox2>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <TextBox1>Type</TextBox1>
                <TextBox2>
                  {selectedRow.wallet === null
                    ? "Aucun"
                    : selectedRow.wallet.typeWallet}
                </TextBox2>
                <TextBox1>Téléphone</TextBox1>
                <TextBox2>
                  {selectedRow.wallet === null
                    ? "Aucun"
                    : selectedRow.wallet.numero}
                </TextBox2>
              </Box>
            </Box>
          )}
          <TextBox1 sx={{ pb: 1 }}>Nom et prénom du demandeur</TextBox1>
          {selectedRow && (
            <TextBox2>
              {selectedRow.emetteur.nom} {selectedRow.emetteur.prenom}
            </TextBox2>
          )}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Référence de la transaction"
            type="email"
            fullWidth
            variant="standard"
            value={motif}
            onChange={(event) => handleInputChange(event, setMotif)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ textTransform: "none", color: "#EF2E2E" }}
          >
            Annuler
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              validateRetrait(selectedRow.numeroTransaction);
              setOpen(false);
            }}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRefuser}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRefuser}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Annuler le retrait"}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          {selectedRow && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pb: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <TextBox1>Date</TextBox1>
                <TextBox2>{formatDate(selectedRow.dateCreation)}</TextBox2>
                <TextBox1>Montant</TextBox1>
                <TextBox2>{selectedRow.valeur}</TextBox2>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <TextBox1>Type</TextBox1>
                <TextBox2>
                  {selectedRow.wallet === null
                    ? "Aucun"
                    : selectedRow.wallet.typeWallet}
                </TextBox2>
                <TextBox1>Téléphone</TextBox1>
                <TextBox2>
                  {selectedRow.wallet === null
                    ? "Aucun"
                    : selectedRow.wallet.numero}
                </TextBox2>
              </Box>
            </Box>
          )}
          <TextBox1 sx={{ pb: 1 }}>Nom et prénom du demandeur</TextBox1>
          {selectedRow && (
            <TextBox2>
              {selectedRow.emetteur.nom} {selectedRow.emetteur.prenom}
            </TextBox2>
          )}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Motif"
            type="email"
            fullWidth
            variant="standard"
            value={motif}
            onChange={(event) => handleInputChange(event, setMotif)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseRefuser}
            sx={{ textTransform: "none", color: "#EF2E2E" }}
          >
            Annuler
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              cancelRetrait(selectedRow.numeroTransaction);
              setOpenRefuser(false);
            }}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Retraits;
