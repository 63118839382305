import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  TextField,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Swal from "sweetalert2";
import AjouterUtilisateur from "./AjouterUtilisateur";
import Api from "../../../services/http/api";
import CustomToast from "../../../helpers/customToast";
import deleteUtilisateurDto from "../dto/deleteUtilisateurDto";
import UtilisateursDto from "../dto/UtilisateursDto";
import updateUtilisateurDto from "../dto/updateUtilisateurDto";

const TabUtilisateurs = () => {
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const initialUserState = {
    id: "",
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    roleUtilisateur: "",
  };

  const [page, setPage] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [filterroleUtilisateur, setFilterroleUtilisateur] = useState("");
  const [deleteUser, setDeleteUser] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editUser, setEditUser] = useState(initialUserState);
  const [validationErrors, setValidationErrors] = useState({});
  const [size, setSize] = useState(150);

  const filteredRows = rows.filter(
    (row) =>
      (!filterName ||
        row.nom.toLowerCase().includes(filterName.toLowerCase())) &&
      (!filterEmail ||
        row.email.toLowerCase().includes(filterEmail.toLowerCase())) &&
      (!filterroleUtilisateur ||
        row.roleUtilisateur
          .toLowerCase()
          .includes(filterroleUtilisateur.toLowerCase()))
  );

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = (user) => {
    setDeleteUser(user);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleConfirmDelete = () => {
    setOpenDeleteModal(false);
    const toastId = CustomToast.showLoading("Suppression en cours.");
    const dto = new deleteUtilisateurDto(deleteUser.id);
    // console.log(dto);
    Api.call(
      dto,
      async (res) => {
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchUsers();
      },
      (err) => {
        console.error("Erreur lors de la suppression :", err);
        CustomToast.hideLoading(toastId);
      }
    );
  };

  const handleEditUser = (user) => {
    setEditUser(user);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setValidationErrors({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    if (!editUser.nom) errors.nom = "Le nom est requis";
    if (!editUser.prenom) errors.prenom = "Le prénom est requis";
    if (!editUser.email) errors.email = "L'email est requis";
    if (!editUser.telephone) errors.telephone = "Le téléphone est requis";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = () => {
    if (!validateForm()) return;
    setOpenEditModal(false);
    const account = {
      email: editUser.email,
      nom: editUser.nom,
      prenom: editUser.prenom,
      role: editUser.roleUtilisateur,
      telephone: editUser.telephone,
    };
    const toastId = CustomToast.showLoading("Modification en cours.");
    const dto = new updateUtilisateurDto(editUser.id, account);
    // console.log(dto);
    // console.log(account);
    Api.call(
      dto,
      async (res) => {
        CustomToast.hideLoading(toastId);
        CustomToast.showSuccess(res.message);
        await fetchUsers();
      },
      (err) => {
        console.error("Erreur lors de la modification :", err);
        CustomToast.hideLoading(toastId);
      }
    );
  };

  const fetchUsers = async () => {
    const dto = new UtilisateursDto("list", 0, size);
    // console.log(dto);
    Api.call(
      dto,
      async (res) => {
        const users = res.content.content;
        const unique = [...new Set(users.map((user) => user.roleUtilisateur))];
        setRoles(unique);
        setRows(users);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  if (rows.length === 0 || roles.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <AjouterUtilisateur onUserAdded={fetchUsers} />
      </Box>

      <Box sx={{ marginBottom: 2, display: "flex", width: "100%", mt: 2 }}>
        <TextField
          label="Nom ou prénom"
          variant="outlined"
          value={filterName}
          size="small"
          onChange={(e) => setFilterName(e.target.value)}
          sx={{ marginRight: 2, width: "35%" }}
        />
        <TextField
          label="Email"
          variant="outlined"
          value={filterEmail}
          size="small"
          onChange={(e) => setFilterEmail(e.target.value)}
          sx={{ marginRight: 2, width: "33.3333333%" }}
        />
        <Autocomplete
          options={roles}
          value={filterroleUtilisateur}
          size="small"
          onChange={(event, newValue) => setFilterroleUtilisateur(newValue)}
          sx={{ marginRight: 2, width: "34%" }}
          renderInput={(params) => (
            <TextField {...params} label="Rôle" variant="outlined" />
          )}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>Utilisateurs</TableCell>
              <TableCell align="right">Rôle</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .filter((row) => row.bloquer === false)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "3px",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          fontWeight: "medium",
                          color: "#333",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {row.nom} {row.prenom}
                      </span>
                      <small style={{ color: "#666", display: "block" }}>
                        {row.email}
                      </small>
                    </div>
                  </TableCell>
                  <TableCell align="right">{row.roleUtilisateur}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleEditUser(row)}
                      sx={{
                        backgroundColor: "#E1FFE0",
                        color: "#00833D",
                        "&:hover": {
                          backgroundColor: "#E1FFE0",
                          color: "#00833D",
                        },
                      }}
                    >
                      <ModeEditOutlineOutlinedIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleDeleteUser(row)}
                      sx={{
                        backgroundColor: "#FFEEEE",
                        mx: 2,
                        color: "#EF2E2E",
                        "&:hover": {
                          backgroundColor: "#FFEEEE",
                          color: "#EF2E2E",
                        },
                      }}
                    >
                      <DeleteForeverOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-user-dialog-title"
        maxWidth="sm" // Set max width of the dialog
      >
        <DialogTitle id="edit-user-dialog-title">
          Modifier l'utilisateur :{" "}
          {`${editUser.nom ? editUser.nom.toUpperCase() : ""} ${editUser.prenom ? editUser.prenom.toUpperCase() : ""}`}{" "}
        </DialogTitle>
        <DialogContent dividers>
          {" "}
          {/* Use dividers to have more padding */}
          <TextField
            autoFocus
            margin="dense"
            id="nom"
            name="nom"
            label="Nom"
            type="text"
            fullWidth
            value={editUser.nom}
            onChange={handleInputChange}
            sx={{ marginBottom: 2 }}
            error={!!validationErrors.nom}
            helperText={validationErrors.nom}
          />
          <TextField
            margin="dense"
            id="prenom"
            name="prenom"
            label="Prénom"
            type="text"
            fullWidth
            value={editUser.prenom}
            onChange={handleInputChange}
            sx={{ marginBottom: 2 }}
            error={!!validationErrors.prenom}
            helperText={validationErrors.prenom}
          />
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={editUser.email}
            onChange={handleInputChange}
            sx={{ marginBottom: 2 }}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
          />
          <TextField
            margin="dense"
            id="telephone"
            name="telephone"
            label="Téléphone"
            type="number"
            fullWidth
            value={editUser.telephone}
            onChange={handleInputChange}
            sx={{ marginBottom: 2 }}
            error={!!validationErrors.telephone}
            helperText={validationErrors.telephone}
          />
          <Autocomplete
            options={roles}
            value={editUser.roleUtilisateur}
            onChange={(event, newValue) =>
              {setEditUser({ ...editUser, roleUtilisateur: newValue });
                console.log(newValue);
                console.log(editUser.roleUtilisateur);
            }
            }
            renderInput={(params) => <TextField {...params} label="Rôle" />}
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditModal}
            color="error"
            sx={{ marginRight: "5%", textTransform: "none" }}
          >
            Annuler
          </Button>
          <Button
            onClick={handleFormSubmit}
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{ borderRadius: 5 }}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 3 }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{ textAlign: "center", fontWeight: "bold", mb: 1 }}
          >
            Supprimer
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "#000", textAlign: "center" }}
            >
              Vous êtes sur le point de supprimer l'utilisateur{" "}
              <b>
                {deleteUser ? `${deleteUser.nom} ${deleteUser.prenom}` : ""}
              </b>
              . Cela entraînera la perte de toutes ses données d’assignations.
            </DialogContentText>
          </DialogContent>
          <div
            style={{
              color: "#EF2E2E",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Supprimer cet utilisateur ?
          </div>
          <DialogActions sx={{ mt: 1 }}>
            <Button
              onClick={handleCloseDeleteModal}
              sx={{ marginRight: "55%", textTransform: "none", color: "#000" }}
            >
              Non
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="primary"
              autoFocus
              sx={{ textTransform: "none" }}
            >
              Oui, supprimer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default TabUtilisateurs;
